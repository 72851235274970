import { Response } from "@/models/request"
import service from "@/api/request"

const api = {
  /**
   * @description 文件上传OSS
   * @author Jinx
   * @date 2021-08-13 15:09:10
   * @param {FormData} data
   * @returns {*}  {Promise<Response>}
   */
  commonOssUpload(data: FormData): Promise<Response> {
    return service({
      url: '/common/ossUpload',
      method: 'post',
      data
    })
  }
}

export default api
