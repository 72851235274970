import { TabListRow } from "@/models/type";
import { defineComponent, toRefs, Transition } from "vue";
import './index.scss'

/**
 *  标签页
 *  @type {*} */

const Tabs = defineComponent({
  props: {
    tabList: {
      type: Array,
      required: true
    },
    activeName: {
      type: String,
      required: true
    },
    onActivechange: Function
  },
  emits: ['activechange'],

  setup(props, { slots, emit }) {

    const { tabList, activeName } = toRefs(props)

    const permissionTabList = getPermissionData(tabList.value)

    /**
     * @description 标签选中事件
     * @author Jinx
     * @date 2021-23-12 15:08:42
     * @param {string} keyName
     */
    function activeChange(e, keyName: string): void {
      e.preventDefault()
      emit('activechange', keyName)
    }

    function getPermissionData(tabList: Array<any>): Array<any> {
      const permissionTabList = []
      const list = Object.assign([], tabList) as Array<TabListRow>
      list.forEach(item => {
        permissionTabList.push(item as never)
      })
      return permissionTabList
    }

    /**
     * @description 标签
     * @author Jinx
     * @date 2021-23-12 15:08:29
     * @returns {*}  {JSX.Element}
     */
    function tab_link(): JSX.Element {
      return <div class="tabs-link">
        {permissionTabList.map((item: any) => (
          <div
            class={[
              "tabs-link__title pointer",
              item.activeName === activeName.value && 'tabs-link__active'
            ]}
            onClick = {(e) => activeChange(e, item.activeName)}
          >
            { item.title }
          </div>
        ))}
      </div>
    }

    /**
     * @description 标签内容
     * @author Jinx
     * @date 2021-23-12 15:08:34
     * @returns {*}  {JSX.Element}
     */
    function tab_view(): JSX.Element {
      return <div class="tab-view">
        <Transition> {/* 显然没用 */}
          { slots[activeName.value]?.() }
        </Transition>
      </div>
    }

    return () => (
      <div class="tabs">
        {tab_link()}
        {tab_view()}
      </div>
    )
  }
})

export default Tabs
