/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import Layout from '@/layout'
import Login from '@/views/login/index.vue'
import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import activityRoute from './modules/activity'
import baseRoute from './modules/base'
import clientRoute from './modules/client'
import companyRoute from './modules/company'
import courseRoute from './modules/course'
import goodRoute from './modules/good'
import mallRoute from './modules/mall'
import signRoute from './modules/sign'
import statisticsRoute from './modules/statistics'
import websiteRoute from './modules/website'

const redirect = () => import('@/views/redirect/index.vue')
const home = () => import('@/views/home/index')

export const constantRoutes: Array<RouteRecordRaw> = [
  {
    path: '/redirect',
    component: Layout,
    children: [
      {
        path: '/redirect/:path(.*)',
        component: redirect
      }
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: { title: '登录', hidden: true }
  },
  {
    path: '/',
    name: 'BasicLayout',
    component: Layout,
    redirect: '/home',
    children: [
      {
        path: 'home',
        component: home,
        name: 'Home',
        meta: { title: '首页', hidden: true },
      },
      {
        path: 'home1',
        component: home,
        name: 'Home1',
        meta: { title: '首页', hidden: true },
      },
      {
        path: 'home2',
        component: home,
        name: 'Home2',
        meta: { title: '首页', hidden: true },
      },
      {
        path: 'home3',
        component: home,
        name: 'Home3',
        meta: { title: '首页', hidden: true },
      },
    ]
  }
]

export const asyncRoutes = [
  websiteRoute,
  courseRoute,
  activityRoute,
  signRoute,
  goodRoute,
  mallRoute,
  clientRoute,
  statisticsRoute,
  companyRoute,
  baseRoute
]


const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes: constantRoutes
})


export const pathDefault = [
  // 默认path 不为''的路由
  '/login'
]

export default router
