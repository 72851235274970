import { Response } from "@/models/request"
import service from "@/api/request"
import { OauthToken } from "@/models/oauth"

// Oauth2获取token
const api = {
  /**
   * @description Oauth2获取token
   * @author Jinx
   * @date 2021-55-11 17:08:01
   * @param {OauthToken} data
   * @returns {*}  {Response}
   */
  oauthToken(params: OauthToken): Promise<Response> {
    return service({
      url: '/mall-auth/oauth/token',
      method: 'post',
      params
    })
  }
}

export default api
