import api from "@/api";
import Button from "@/components/Button";
import Pagination from "@/components/Pagination";
import Search from "@/components/Search";
import { UmsResource, UmsResourceCategory } from "@/models/resource";
import { ButtonListRow, PaginationRow, SearchListRow, TableRow } from "@/models/type";
import { debounceClick, parseTime, typeWatch } from "@/utils";
import { ElMessageBox, ElNotification } from "element-plus";
import { Options, Vue } from "vue-class-component";

@Options({
  data() {
    return {
      resourceForm: {},
      tableData: [],
      resourceCategoryList: []
    }
  },
  watch: {
    type(val?: string): void {
      this.title = typeWatch(val)
    },
    deleteSum(sum: number): void {
      if(sum) {
        this.getList()
      }
    }
  }
})
export default class CompanyResource extends Vue {
  private resourceCategoryBool = false

  private deleteSum = 0
  public searchObj: any = {}

  private total = 0

  private type = ''
  private title = ''
  private detailBool = false
  private ids:Array<number> = []

  private resourceCategoryList: Array<any> = []
  private tableData: Array<any> = []

  private tableList: Array<TableRow> = [
    { label: '资源名称', prop: 'name' },
    { label: '资源路径', prop: 'url' },
    { label: '描述', prop: 'description' },
    { label: '创建时间', prop: 'createTime', slot: {
      default: (scope: any): JSX.Element => <span>{ parseTime(new Date(scope.row.createTime).getTime()) }</span>
    } },
    { label: '操作', slot: {
      default: (scope: any): JSX.Element => <div>
        <span class="span-button-primary" onClick={ () => this.resourceEdit(scope.row) }>编辑</span>
        <span class="span-button-danger" onClick={ () => this.resourceDelete(scope.row.id) }>删除</span>
      </div>
    } }
  ]

  private buttonList: Array<ButtonListRow> = [
    { title: '添加', icon: 'el-icon-plus', click: 'resourceAdd' },
    { title: '删除', icon: 'el-icon-minus', type: 'danger', click: 'resourceDelete' },
    { title: '分类设置', type: 'primary', click: 'resourceCategoryChange' },
  ]

  private searchList: Array<SearchListRow> = []

  public resourceForm: UmsResource = {
    name: '',
    url: '',
    categoryId: '',
    description: ''
  }

  public formRules: any = {}

  /**
   * @description 按钮点击操作
   * @author Jinx
   * @date 2021-08-18 14:02:32
   * @param {string} click
   * @memberof CompanyResource
   */
  buttonClick(click: string): void {
    this[click]()
  }

  resourceAdd(): void {
    this.type = 'add'
    this.detailBool = true
  }

  resourceEdit(row: UmsResource): void {
    this.resourceForm = Object.assign({}, row)
    this.type = 'edit'
    this.detailBool = true
  }

  /**
   * @description 分类设置
   * @author Jinx
   * @date 2021-08-18 14:46:35
   * @memberof CompanyResource
   */
  resourceCategoryChange(): void {
    this.resourceCategoryBool = true
  }

  resourceDelete(id: number): void {
    const ids = id ? [id] : this.ids
    if(!(ids.length > 0)) {
      ElNotification({
        type: 'warning',
        message: '请选择要删除的资源列表'
      })
      return
    }
    ElMessageBox.confirm('确定删除该资源？', '删除资源', {
      type: 'warning'
    }).then(() => {
      api.resourceDelete(ids).then(() => {
        ElNotification({
          title: '成功',
          type: 'success',
          message: '删除资源成功'
        })
        id ? this.deleteSum++ : this.getList()
      })
    }).catch(err => {
      return err
    })
  }

  selectionChange(row: Array<any>): void {
    this.ids = row.map(item => item.id)
  }

  detailClose(): void {
    this.detailBool = false
    this.type = ''
    this.resourceForm = {
      name: '',
      url: '',
      categoryId: '',
      description: ''
    }
  }

  /**
   * @description 分类设置dom
   * @author Jinx
   * @date 2021-08-18 15:27:36
   * @returns {*}  {JSX.Element}
   * @memberof CompanyResource
   */
  resourceCategoryDom(): JSX.Element {
    const slots = {
      // 对话框底部元素
      footer: () => (
        <span class="dialog-footer">
          <button class="dialog-footer-btn dialog-footer-btn-l" onClick={ this.resourceCategoryClose }>取 消</button>
          <button class="dialog-footer-btn dialog-footer-btn-r" onClick={ this.resourceCategoryClose }>确 定</button>
        </span>
      )
    }

    return (
      <el-dialog
        title="分类设置"
        v-model={ this.resourceCategoryBool }
        width="30%"
        before-close={ this.resourceCategoryClose }
        v-slots={ slots }
        custom-class="resource-dialog"
      >
        {this.resourceCategoryList.map((item, index) => {
          const slot = {
            suffix: () => <span class="pointer slot-span" onClick={this.resourceCategoryCellChange.bind(this, index)} >{ item.disabled ? '编辑' : '保存' }</span>
          }
          return (
            <span class="slot-content">
              <el-input v-model={item.name} disabled={item.disabled} v-slots={ slot } />
              <span class="el-icon-close slot-content-close pointer" onClick={ this.resourceCategoryCellDelete.bind(this, index) } />
            </span>
          )
        })}
        <div class="slot-btn__wapper">
          <button class="slot-btn" onClick={ this.resourceCategoryCellAdd }>添加</button>
        </div>
      </el-dialog>
    )
  }

  /**
   * @description 添加分类
   * @author Jinx
   * @date 2021-08-18 16:18:38
   * @memberof CompanyResource
   */
  resourceCategoryCellAdd(): void {
    const obj:UmsResourceCategory = {
      name: '',
      disabled: false
    }
    this.resourceCategoryList.push(obj)
  }

  /**
   * @description 分类数据增改
   * @author Jinx
   * @date 2021-08-18 16:08:26
   * @param {number} index
   * @memberof CompanyResource
   */
  resourceCategoryCellChange(index: number): void {
    const row = this.resourceCategoryList[index] as UmsResourceCategory
    if(!row.disabled) {
      row.id
       ? (
         api.resourceCategoryUpdate(row.id as number, row).then(() => {
           ElNotification({
             title: '成功',
             type: 'success',
             message: '修改分类成功'
           })
         })
       )
       : (
         api.resourceCategoryCreate(row).then(() => {
          ElNotification({
            title: '成功',
            type: 'success',
            message: '新增分类成功'
          })
         })
       )
    }
    row.disabled = !row.disabled
  }

  /**
   * @description 分类数据删除
   * @author Jinx
   * @date 2021-08-18 16:09:02
   * @memberof CompanyResource
   */
  resourceCategoryCellDelete(index: number): void {
    const row = this.resourceCategoryList[index] as UmsResourceCategory

    ElMessageBox.confirm('确定删除该资源分类？', '删除资源分类', {
      type: 'warning'
    }).then(() => {
      if(row.id) {
        api.resourceCategoryDelete(row.id as number)
      }
      this.resourceCategoryList.splice(index, 1)
      ElNotification({
        title: '成功',
        type: 'success',
        message: '资源分类删除成功'
      })
    }).catch(err => {
      return err
    })
  }

  /**
   * @description 分类关闭
   * @author Jinx
   * @date 2021-08-18 15:27:59
   * @memberof CompanyResource
   */
  resourceCategoryClose(): void {
    this.resourceCategoryBool = false
    this.getResourceCategoryList()
  }

  detailDom(): JSX.Element {
    const slots = {
      // 对话框底部元素
      footer: () => (
        <span class="dialog-footer">
          <button class="dialog-footer-btn dialog-footer-btn-l" onClick={ this.detailClose } >取 消</button>
          <button class="dialog-footer-btn dialog-footer-btn-r" onClick={ () => debounceClick(this.submit) }>确 定</button>
        </span>
      )
    }

    return (
      <el-dialog
        title={ `${this.title}资源` }
        v-model={ this.detailBool }
        width="30%"
        before-close={ this.detailClose }
        v-slots={ slots }
      >
        <el-form
          ref="form"
          model={ this.resourceForm }
          rules={ this.formRules }
          label-width="120px"
          class="el-form-tao"
        >
          <el-form-item label="资源名称" prop="name">
            <el-input v-model={ this.resourceForm.name } placeholder="请输入资源名称" clearable />
          </el-form-item>
          <el-form-item label="资源分类" prop="categoryId">
            <el-select v-model={ this.resourceForm.categoryId } placeholder="请输入资源分类" clearable style="width: 100%">
              {this.resourceCategoryList.map(item => <el-option label={ item.name } value={ item.id } />)}
            </el-select>
          </el-form-item>
          <el-form-item label="资源路径" prop="url">
            <el-input v-model={ this.resourceForm.url } placeholder="请输入资源路径" clearable />
          </el-form-item>
          <el-form-item label="资源描述" prop="description">
            <el-input type="textarea" v-model={ this.resourceForm.description } placeholder="请输入资源描述" clearable />
          </el-form-item>
        </el-form>
      </el-dialog>
    )
  }

  submit(): void {
    const __form = this.$refs.form as any
    __form.validate(v => {
      if(v) {
        const form = Object.assign({}, this.resourceForm)
        this.type === 'add'
          ? this.submitAdd(form)
          : this.submitEdit(form)
      } else {
        return false
      }
    })
  }

  submitAdd(form: UmsResource): void {
    delete form.id
    api.resourceCreate(form).then(() => {
      ElNotification({
        title: '成功',
        type: 'success',
        message: '添加资源成功'
      })
      this.detailClose()
      this.getList()
    })
  }

  submitEdit(form: UmsResource): void {
    api.resourceUpdate(form.id as number, form).then(() => {
      ElNotification({
        title: '成功',
        type: 'success',
        message: '编辑资源成功'
      })
      this.detailClose()
      this.getList()
    })
  }

  onSearch(obj: never): void {
    this.searchObj = obj
    this.getList()
  }

  getList(): void {
    const __pagination = this.$refs.pagination as any
    const { pageSize, pageNum } = __pagination.paginationObj as PaginationRow

    api.resourceList({ pageSize, pageNum, ...this.searchObj }).then(res => {
      this.total = res.data.total
      this.tableData = res.data.list
    })
  }

  getResourceCategoryList(): void {
    api.resourceCategoryListAll().then(res => {
      res.data.forEach(item => item.disabled = true)
      this.resourceCategoryList = res.data
      const list = res.data.map(item => ({ name: item.name, value: item.id }))
      this.searchList = [
        { label: '资源名称', type: 'input', submitKey: 'nameKeyword' },
        { label: '资源路径', type: 'input', submitKey: 'urlKeyword' },
        { label: '资源分类', type: 'select', submitKey: 'categoryId', options: list }
      ]
    })
  }

  created(): void {
    this.getResourceCategoryList()
  }

  render(): JSX.Element {
    return (
      <div class="company-resource">
        <Search list={ this.searchList } onSearch={this.onSearch} />
        <Button list={ this.buttonList } onClick={this.buttonClick}/>
        <el-table
          data={ this.tableData }
          onSelectionChange={ this.selectionChange }
        >
          <el-table-column type="selection" width="55" />
          {this.tableList.map(item => (
            <el-table-column
              label={ item.label }
              prop={ item.prop }
              align={ item.align || 'center' }
              v-slots={ item.slot }
            />
          ))}
        </el-table>
        <Pagination ref="pagination" total={ this.total } onSizeChange={ this.getList } onCurrentChange={ this.getList } />
        {this.detailBool && this.detailDom()}
        {this.resourceCategoryBool && this.resourceCategoryDom()}
      </div>
    )
  }

  mounted(): void {
    this.getList()
  }
}
