import { isExternal } from "@/utils/validate"
import path from "path"
import { OnlyOneChildRow } from "@/models/type"
import AppLink from "./Link"
import Item from "./Item"
import { defineComponent, ref, toRefs } from "vue"
import { pathDefault } from "@/router"

/** SidebarItem 菜单
 *  @type {*}
 * */
const SidebarItem = defineComponent({
  props: {
    item: {
      type: Object,
      required: true
    },
    isNest: {
      type: Boolean,
      default: false
    },
    basePath: {
      type: String,
      default: ''
    }
  },
  setup(props){
    const { item, isNest, basePath } = toRefs(props)

    const onlyOneChild: OnlyOneChildRow = ref({})

    /**
     * @description 菜单路由显示
     * @author Jinx
     * @date 2021-08-05 15:06:41
     * @param {any[]} children
     * @param {*} parent
     * @returns {*}  {boolean}
     */
    function hasOneShowingChild(children: any[], parent: any): boolean {
      const showingChildren = children.filter(item => {
        if(item.meta && item.meta.hidden) {
          return false
        } else {
          // 临时设置（如果只有一个显示孩子将使用）
          onlyOneChild.value = item
          return true
        }
      })

      // 当只有一个子路由器时，默认显示子路由器
      if(showingChildren.length === 1) {
        return true
      }

      // 如果没有要显示的子路由器，则显示父级
      if(showingChildren.length === 0) {
        onlyOneChild.value = { ...parent, path: pathDefault.includes(parent.path) ? parent.path : '', noShowingChildren: true }
        return true
      }

      return false
    }

    /**
     * @description 判断路由正确性
     * @author Jinx
     * @date 2021-08-05 15:13:00
     * @param {*} routePath
     * @returns {*}
     */
    function resolvePath(routePath: string): any {
      if (isExternal(routePath)) {
        return routePath
      }
      if (isExternal(basePath.value)) {
        return basePath.value
      }
      return path.resolve(basePath.value, routePath)
    }


    /**
     * @description 隐藏子路由
     * @author Jinx
     * @date 2021-08-06 10:11:32
     * @returns {*}
     */
    function hiddenChild(){
      return onlyOneChild.value.meta && !onlyOneChild.value.meta.hidden && <AppLink class={`el-menu__${onlyOneChild.value.meta.icon}`} to={resolvePath(onlyOneChild.value.path)}>
        <el-menu-item index={resolvePath(onlyOneChild.value.path)} class={{'submenu-title-noDropdown': !isNest.value}}>
          <Item icon={onlyOneChild.value.meta.icon || (item.value.meta && item.value.meta.icon)} title={onlyOneChild.value.meta.title} />
        </el-menu-item>
      </AppLink>
    }

    let isOne = false
    isOne = hasOneShowingChild(item.value.children || [], item.value)
      && (!(onlyOneChild.value.children) || onlyOneChild.value.noShowingChildren)
      && !item.value.alwaysShow

    const titleSlot = {
      title: () => <Item icon={ item.value.meta && item.value.meta.icon } title={ item.value.meta.title } />
    }

    return () => (
      isOne
        ? hiddenChild()
        : <el-submenu index={resolvePath(item.value.path as string)} popper-append-to-body v-slots={titleSlot}>
            {item.value.children.map(child => (
              <SidebarItem class="nest-menu" key={child.path} isNest={true} item={child} basePath={resolvePath(child.path)} />
            ))}
          </el-submenu>
    )
  }
})

export default SidebarItem
