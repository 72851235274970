import { Response } from "@/models/request"
import service from "@/api/request"
import { PaginationRow } from "@/models/type"
import { UmsMemberParam, UmsTeamParam } from "@/models/team"
import { SystemGetSignUpListParams } from "./system"

const api = {
  /**
   * @description 获取团队成员列表
   * @author Jinx
   * @date 2021-10-20 16:12:53
   * @param {TeamListParams} params
   * @returns {*}  {Promise<Response>}
   */
  teamGetTeamList(params: TeamListParams): Promise<Response> {
    return service({
      url: '/team/getTeamList',
      method: 'get',
      params
    })
  },
  /**
   * @description 新增团队
   * @author Jinx
   * @date 2021-10-20 16:59:27
   * @param {UmsTeamParam} data
   * @returns {*}  {Promise<Response>}
   */
  teamAddTeam(data: UmsTeamParam): Promise<Response> {
    return service({
      url: '/team/addTeam',
      method: 'post',
      data
    })
  },
  /**
   * @description 修改團隊管理賬號信息
   * @author Jinx
   * @date 2021-10-20 17:00:08
   * @param {UmsTeamParam} data
   * @returns {*}  {Promise<Response>}
   */
  teamEditTeamInfo(data: UmsTeamParam): Promise<Response> {
    return service({
      url: '/team/editTeamInfo',
      method: 'post',
      data
    })
  },
  /**
   * @description 修改会员密码
   * @author Jinx
   * @date 2021-11-15 13:36:49
   * @param {{id: number, password: string}} params
   * @returns {*}  {Promise<Response>}
   */
  teamEditMemberPassword(params: {id: number, password: string}): Promise<Response> {
    return service({
      url: '/team/editMemberPassword',
      method: 'post',
      params
    })
  },
  /**
   * @description 删除团队
   * @author Jinx
   * @date 2021-10-20 16:44:07
   * @param {Array<number>} data
   * @returns {*}  {Promise<Response>}
   */
  teamDeleteTeam(data: Array<number>): Promise<Response> {
    return service({
      url: '/team/deleteTeam',
      method: 'post',
      data
    })
  },
  /**
   * @description 启用停用团队
   * @author Jinx
   * @date 2021-10-20 16:45:47
   * @param {Array<number>} data
   * @param {{status: number}} params
   * @returns {*}  {Promise<Response>}
   */
  teamSetTeamStatus(data: Array<number>, params: {status: number}): Promise<Response> {
    return service({
      url: '/team/setTeamStatus',
      method: 'post',
      data,
      params
    })
  },
  /**
   * @description 根据团队id获取所有的会员列表
   * @author Jinx
   * @date 2021-10-21 10:20:51
   * @param {{id: number}} params
   * @returns {*}  {Promise<Response>}
   */
  teamGetMemberInfoByTeamId(params: {id: number}): Promise<Response> {
    return service({
      url: '/team/getMemberInfoByTeamId',
      method: 'post',
      params
    })
  },
  /**
   * @description 获取会员列表
   * @author Jinx
   * @date 2021-10-22 10:45:58
   * @param {TeamGetMemberListParams} params
   * @returns {*}  {Promise<Response>}
   */
  teamGetMemberList(params: TeamGetMemberListParams): Promise<Response> {
    return service({
      url: '/team/getMemberList',
      method: 'post',
      params
    })
  },
  /**
   * @description 根据会员id获取会员详细信息
   * @author Jinx
   * @date 2021-11-05 15:17:50
   * @param {number} id
   * @returns {*}  {Promise<Response>}
   */
  teamGetMemberById(id: number): Promise<Response> {
    return service({
      url: `/team/getMemberById/${id}`,
      method: 'get'
    })
  },
  /**
   * @description 启用停用账号
   * @author Jinx
   * @date 2021-10-22 11:00:50
   * @param {{status: number}} params
   * @param {Array<number>} data
   * @returns {*}  {Promise<Response>}
   */
  teamSetMemberStatus(params: {status: number}, data: Array<number>): Promise<Response> {
    return service({
      url: '/team/setMemberStatus',
      method: 'post',
      params,
      data
    })
  },
  /**
   * @description 获取报名记录
   * @author Jinx
   * @date 2021-10-22 11:26:32
   * @param {SystemGetSignUpListParams} params
   * @returns {*}  {Promise<Response>}
   */
  teamGetSignUpRecord(params: SystemGetSignUpListParams): Promise<Response> {
    return service({
      url: '/team/getSignUpRecord',
      method: 'post',
      params
    })
  },
  /**
   * @description 添加会员
   * @author Jinx
   * @date 2021-10-22 15:47:02
   * @param {UmsMemberParam} data
   * @returns {*}  {Promise<Response>}
   */
  teamAddMember(data: UmsMemberParam): Promise<Response> {
    return service({
      url: '/team/addMember',
      method: 'post',
      data
    })
  },
  /**
   * @description 根據id獲取團隊管理賬號詳情
   * @author Jinx
   * @date 2021-11-15 09:51:42
   * @param {{id: number}} params
   * @returns {*}  {Promise<Response>}
   */
  teamGetTeamAccountById(params: {id: number}): Promise<Response> {
    return service({
      url: '/team/getTeamAccountById',
      method: 'get',
      params
    })
  },
  /**
   * @description 删除会员
   * @author Jinx
   * @date 2021-10-22 16:48:50
   * @param {Array<number>} data
   * @returns {*}  {Promise<Response>}
   */
  teamDeleteMember(data: Array<number>): Promise<Response> {
    return service({
      url: '/team/deleteMember',
      method: 'post',
      data
    })
  },
  /**
   * @description 编辑会员
   * @author Jinx
   * @date 2021-11-05 18:10:20
   * @param {UmsMemberParam} data
   * @returns {*}  {Promise<Response>}
   */
  teamEditMember(data: UmsMemberParam): Promise<Response> {
    return service({
      url: '/team/editMember',
      method: 'post',
      data
    })
  }
}

export default api

export interface TeamListParams extends PaginationRow {
  cardId?: string,
  email?: string,
  id?: number,
  name?: string
  phone?: string
  title?: string
}


export interface TeamGetMemberListParams extends PaginationRow {
  kind: number,
  cardId?: string,
  email?: string,
  endTime?: Date | string,
  id?: number
  memberId?: string,
  name?: string,
  phone?: string,
  startTime?: string
}
