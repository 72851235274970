import { Response } from "@/models/request"
import service from "@/api/request"
import { DefaultSetting, PaginationRow } from "@/models/type"

const api = {
  /**
   * @description 获取评论列表
   * @author Jinx
   * @date 2021-11-01 09:32:41
   * @param {CommentParams} params
   * @returns {*}  {Promise<Response>}
   */
  getCommentList(params: CommentParams): Promise<Response> {
    return service({
      url: '/comment/getCommentList',
      method: 'get',
      params
    })
  },
  /**
   * @description 回复评论
   * @author Jinx
   * @date 2021-11-01 11:46:59
   * @param {PmsCommentReplay} data
   * @returns {*}  {Promise<Response>}
   */
  replayComment(data: PmsCommentReplay): Promise<Response> {
    return service({
      url: '/comment/replayComment',
      method: 'post',
      data
    })
  },
  /**
   * @description 编辑回复
   * @author Jinx
   * @date 2021-11-01 11:57:34
   * @param {PmsCommentReplay} data
   * @returns {*}  {Promise<Response>}
   */
  editReplayComment(data: PmsCommentReplay): Promise<Response> {
    return service({
      url: '/comment/editReplayComment',
      method: 'post',
      data
    })
  },
  /**
   * @description 删除评论
   * @author Jinx
   * @date 2021-11-01 11:48:04
   * @param {number} id
   * @returns {*}  {Promise<Response>}
   */
  replayCommentDelete(id: number): Promise<Response> {
    return service({
      url: `/comment/replayComment/${id}`,
      method: 'get'
    })
  },
  /**
   * @description 删除回复
   * @author Jinx
   * @date 2021-11-01 11:51:55
   * @param {number} id
   * @returns {*}  {Promise<Response>}
   */
  deleteReplayComment(id: number): Promise<Response> {
    return service({
      url: `/comment/deleteReplayComment/${id}`,
      method: 'get'
    })
  },
}

export default api

export interface CommentParams extends PaginationRow {
  productSn?: string
  startTime?: Date | string
  endTime?: Date | string
}

export interface PmsCommentReplay extends DefaultSetting {
  commentId?: number,
	content?: string,
	type?: number
}
