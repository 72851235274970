
import api from "@/api";
import { UmsAdminLoginParam } from "@/models/admin";
import { Cb } from "@/models/type";
import { getToken, removeToken, setToken } from "@/utils/auth";
import { ElNotification } from "element-plus";
import { ActionContext } from "vuex";


export interface UserStoreRow {
  token?: string,
  name?: string,
  role?: string[],
  menu?: any[],
  icon?: string
}

const userStore = {

  state: {
    token: getToken(),
    name: '',
    role: [],
    menu: [],
    icon: ''
  } as UserStoreRow,

  mutations: {
    SET_TOKEN: (state: UserStoreRow, token?: string): void => {
      state.token = token
    },
    SET_NAME: (state: UserStoreRow, name?: string): void => {
      state.name = name
    },
    SET_ROLE: (state: UserStoreRow, role?: Array<string>): void => {
      state.role = role
    },
    SET_MENU: (state: UserStoreRow, menu?: Array<any>): void => {
      state.menu = menu
    },
    SET_ICON: (state: UserStoreRow, icon?: string): void => {
      state.icon = icon
    },
  },

  actions: {

    /**
     * @description 登录
     * @author Jinx
     * @date 2021-08-13 10:05:18
     * @param {ActionContext<UserStoreRow, UserStoreRow>} {commit}
     * @param {UmsAdminLoginParam} loginForm
     * @returns {*}  {Promise<any>}
     */
    async login({commit}: ActionContext<UserStoreRow, UserStoreRow>, loginForm: UmsAdminLoginParam): Promise<any> {
      const { username, password } = loginForm
      try {
        /* const { data } = await api.oauthToken({
          username,
          password: password.trim(),
          client_id: 'admin-app',
          client_secret: '123456',
          grant_type: 'password'
        })
        commit('SET_TOKEN', data.tokenHead + data.token)
        setToken(data.tokenHead + data.token) */
        return new Promise((resolve, reject) => {
          api.adminLogin({
            username,
            password: password.trim(),
          }).then(res => {
            const { tokenHead, token } = res.data
            commit('SET_TOKEN', tokenHead + token)
            setToken(tokenHead + token)
            resolve(res.data)
          }).catch(() => {
            reject(false)
          })
        })
      } catch (error) {
        return false
      }
    },

    /**
     * @description 获取用户信息
     * @author Jinx
     * @date 2021-08-13 10:05:08
     * @param {ActionContext<UserStoreRow, UserStoreRow>} {commit}
     * @returns {*}  {Promise<any>}
     */
    getInfo({commit}: ActionContext<UserStoreRow, UserStoreRow>): Promise<any> {
      return new Promise((resolve, reject) => {
        api.adminInfo().then(res => {
          const { username, icon, roles, menus } = res.data
          commit('SET_NAME', username)
          commit('SET_ROLE', roles)
          commit('SET_MENU', menus)
          commit('SET_ICON', icon)
          resolve(res.data)
        }).catch(err => {
          reject(err)
        })
      })
    },

    /**
     * @description 退出登录
     * @author Jinx
     * @date 2021-08-12 10:39:44
     * @param {ActionContext<UserStoreRow, UserStoreRow>} {commit}
     * @returns {*}  {Promise<void>}
     */
    logout({commit}: ActionContext<UserStoreRow, UserStoreRow>): Promise<void>{
      return new Promise((resolve, reject) => {
        api.adminLogout().then(() => {
          commit('SET_TOKEN', '')
          commit('SET_ROLE', [])
          commit('SET_NAME', '')
          removeToken()

          ElNotification({
            title: '成功',
            type: 'success',
            message: '退出成功'
          })

          resolve()
        }).catch(err => {
          reject(err)
        })
      })
    },

    /**
     * @description 去除用户个人信息
     * @author Jinx
     * @date 2021-08-13 09:35:50
     * @param {ActionContext<UserStoreRow, UserStoreRow>} {commit}
     * @returns {*}  {Promise<boolean>}
     */
    resetToken({commit}: ActionContext<UserStoreRow, UserStoreRow>): Promise<boolean> {
      return new Promise(resolve => {
        commit('SET_TOKEN', '')
        commit('SET_ROLE', [])
        commit('SET_NAME', '')
        removeToken()
        resolve(true)
      })
    }
  },

  getters: {
    GetToken: (state): Cb => state.token,
    GetName: (state): Cb => state.name,
    GetMenu: (state): Cb => state.menu
  }

}


export default userStore
