import router from './router'
import store from './store'
import { getToken } from './utils/auth'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css' // progress bar style

NProgress.configure({ showSpinner: false })

const whiteList = ['/login', '/auth-redirect'] // no redirect whitelist

router.beforeEach(async(to, from, next) => {

  NProgress.start()

  // TODO 权限部分

  const hasToken = getToken()
  if (hasToken) {
    if(to.path === '/login') {
      // 如果在登录 返回首页
      next({ path: '/' })
      NProgress.done()
    } else {
      const name = store.getters.GetName
      if(name) {
        next()
        NProgress.done()
      }else {
        try{
          const $state = store.state as any
            //TAG 一定要有一个执行条件 否则会进入死循环
            if(!$state.user.role.length) {

              const { menus } = await store.dispatch('getInfo')
              const isRoute = await store.dispatch('generateRoutesAsync', menus)
              if(isRoute) {
                //TODO 动态获取路由 之后做的事情
              }
              const accessRoutes = await store.dispatch('generateRoutes', menus)

              if(accessRoutes.length > 0) {
                  accessRoutes.filter(route => {
                    router.addRoute('BasicLayout', route)
                  })
                  // 如果 addRoute 并未完成，路由守卫会一层一层的执行执行，直到 addRoute 完成，找到对应的路由
                  next({...to, replace: true})
                  NProgress.done()
              }

            } else {
              next()
              NProgress.done()
            }
        } catch (error) {

          await store.dispatch('resetToken')

          next(`/login?redirect=${to.path}`)
          NProgress.done()
        }
      }
    }

  } else {
    if (whiteList.indexOf(to.path) !== -1) {
      next()
      NProgress.done()
    } else {
      next(`/login?redirect=${to.path}`)
      NProgress.done()
    }
  }
})

router.afterEach(() => {
  // finish progress bar
})
