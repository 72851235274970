/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { RouteRecordRaw } from "vue-router";
import Client from '@/views/client/index.vue'

const Ordinary = () => import('@/views/client/ordinary')
const OneVip = () => import('@/views/client/oneVip')
const MoreVip = () => import('@/views/client/moreVip')

/* 账户管理 */
const clientRoute: RouteRecordRaw = {
  name: 'Client',
  path: '/client',
  component: Client,
  redirect: '/client/ordinary',
  meta: { title: '账户管理', icon: 'client', roles: ['账户管理'] },
  children: [
    {
      name: 'ClientOrdinary',
      path: 'ordinary',
      component: Ordinary,
      meta: { title: '普通账户', roles: ['账户管理'] },
    },
    {
      name: 'ClientOneVip',
      path: 'one_vip',
      component: OneVip,
      meta: { title: '个人会员', roles: ['账户管理'] }
    },
    {
      name: 'MoreVip',
      path: 'more_vip',
      component: MoreVip,
      meta: { title: '团队会员', roles: ['账户管理'] }
    }
  ]
}

export default clientRoute
