/* 公司管理 */
import Base from '@/views/base'

const baseRoute = {
  path: '/base',
  name: 'Base',
  component: Base,
  meta: { title: '基础设置', icon: 'base', roles: ['基础设置'] }
}

export default baseRoute
