import { Response } from "@/models/request"
import service from "@/api/request"
import { SmsGoodWork, SmsGoodWorkParams } from "@/models/good"

/**
 *  作品管理
 *  @type {*} */
const api = {
  /**
   * @description 获取优秀作品列表
   * @author Jinx
   * @date 2021-08-27 15:03:52
   * @param {SmsGoodWorkParams} params
   * @returns {*}  {Promise<Response>}
   */
  goodworkGetGoodWorkList(params: SmsGoodWorkParams): Promise<Response> {
    return service ({
      url: '/goodwork/getGoodWorkList',
      method: 'get',
      params
    })
  },

  /**
   * @description 添加优秀作品
   * @author Jinx
   * @date 2021-08-27 15:05:39
   * @param {SmsGoodWork} data
   * @returns {*}  {Promise<Response>}
   */
  goodworkAddGoodWork(data: SmsGoodWork): Promise<Response> {
    return service ({
      url: '/goodwork/addGoodWork',
      method: 'post',
      data
    })
  },

  /**
   * @description 编辑优秀作品
   * @author Jinx
   * @date 2021-08-27 15:05:43
   * @param {SmsGoodWork} data
   * @returns {*}  {Promise<Response>}
   */
  goodworkEditGoodWork(data: SmsGoodWork): Promise<Response> {
    return service ({
      url: '/goodwork/editGoodWork',
      method: 'post',
      data
    })
  },

  /**
   * @description 删除优秀作品
   * @author Jinx
   * @date 2021-08-27 15:05:44
   * @param {Array<number>} data
   * @returns {*}  {Promise<Response>}
   */
  goodworkDeleteGoodWork(data: Array<number>): Promise<Response> {
    return service ({
      url: '/goodwork/deleteGoodWork',
      method: 'post',
      data
    })
  }
}

export default api
