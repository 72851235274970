import { Vue } from "vue-class-component";
import { RouterLink } from "vue-router";

export default class Logo extends Vue {
  private logo = '/logo.png'

  render(): JSX.Element {
    return (
      <div class="sidebar-logo">
        <RouterLink to="/">
          <img src={this.logo} alt="道毅空间" />
        </RouterLink>
      </div>
    )
  }
}
