import { Response } from "@/models/request"
import service from "@/api/request"
import { PaginationRow } from "@/models/type"

const api = {
  /**
   * @description 统计信息
   * @author Jinx
   * @date 2021-10-21 17:23:10
   * @param {SystemOrderParams} params
   * @returns {*}  {Promise<Response>}
   */
  systemOrder(params: SystemOrderParams): Promise<Response> {
    return service({
      url: '/system/order',
      method: 'get',
      params
    })
  },
  /**
   * @description 报名记录
   * @author Jinx
   * @date 2021-10-21 17:24:33
   * @param {SystemGetSignUpListParams} params
   * @returns {*}  {Promise<Response>}
   */
  systemGetSignUpList(params: SystemGetSignUpListParams): Promise<Response> {
    return service({
      url: '/system/getSignUpList',
      method: 'get',
      params
    })
  },
  /**
   * @description 活动
   * @author Jinx
   * @date 2021-10-21 18:25:27
   * @param {{end_time?: string, start_time?: string}} params
   * @returns {*}  {Promise<Response>}
   */
  systemGetActivityStatistics(params: {end_time?: string, start_time?: string}): Promise<Response> {
    return service({
      url: '/system/getActivityStatistics',
      method: 'get',
      params
    })
  },
  /**
   * @description 课程
   * @author Jinx
   * @date 2021-10-21 18:38:27
   * @param {{subjectId?: number}} params
   * @returns {*}  {Promise<Response>}
   */
  systemGetSubjectStatistics(params: {subjectId?: number}): Promise<Response> {
    return service({
      url: '/system/getSubjectStatistics',
      method: 'get',
      params
    })
  },
  /**
   * @description 根据课程名称获取课程列表
   * @author Jinx
   * @date 2021-10-21 18:51:13
   * @param {{title?: string}} params
   * @returns {*}  {Promise<Response>}
   */
  systemGetSubjectListBySubjectName(params: {title?: string}): Promise<Response> {
    return service({
      url: '/system/getSubjectListBySubjectName',
      method: 'get',
      params
    })
  },
  /**
   * @description 用户总览
   * @author Jinx
   * @date 2021-10-22 09:34:39
   * @returns {*}  {Promise<Response>}
   */
  systemGetMemberStatistics(): Promise<Response> {
    return service({
      url: '/system/getMemberStatistics',
      method: 'get'
    })
  },
  /**
   * @description 订单总览
   * @author Jinx
   * @date 2021-10-22 09:35:40
   * @returns {*}  {Promise<Response>}
   */
  systemGetOrderStatistics(): Promise<Response> {
    return service({
      url: '/system/getOrderStatistics',
      method: 'get'
    })
  },
  /**
   * @description 商品总览
   * @author Jinx
   * @date 2021-10-22 09:36:27
   * @returns {*}  {Promise<Response>}
   */
  systemGetProductStatistics(): Promise<Response> {
    return service({
      url: '/system/getProductStatistics',
      method: 'get'
    })
  },
  /**
   * @description 报名总览
   * @author Jinx
   * @date 2021-10-22 09:37:19
   * @returns {*}  {Promise<Response>}
   */
  systemGetSignUpStatistics(params: SystemGetSignUpListParams): Promise<Response> {
    return service({
      url: '/system/getSignUpStatistics',
      method: 'get',
      params
    })
  }
}

export default api

export interface SystemOrderParams {
  selectType?: string,
  type?: string,
  memberLevel?: number,
  productKind?: number,
  productSn?: string,
  selectTime?: string
}

export interface SystemGetSignUpListParams extends PaginationRow {
  type?: string
  title?: string,
  id?: number
}
