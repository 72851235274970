import api from "@/api";
import Button from "@/components/Button";
import Pagination from "@/components/Pagination";
import Search from "@/components/Search";
import { UmsAdminParam } from "@/models/admin";
import { ButtonListRow, OptionsRow, PaginationRow, SearchListRow, TableRow } from "@/models/type";
import { debounceClick, getFile, typeWatch } from "@/utils";
import { isEmail } from "@/utils/validate";
import { ElMessageBox, ElNotification } from "element-plus";
import { Options, Vue } from "vue-class-component";
import md5 from 'md5'

/**
 * @description 员工管理
 * @author Jinx
 * @date 2021-08-17 15:07:22
 * @export
 * @class CompanyStaff
 * @extends {Vue}
 */
@Options({
  components: { Button, Search },
  data() {
    return {
      tableData: [],
      staffForm: {}
    }
  },
  watch: {
    type(val?: string): void {
      this.title = typeWatch(val)
    },
    deleteSum(sum: number): void {
      if(sum) {
        this.getList()
      }
    }
  }
})
export default class CompanyStaff extends Vue {

  private deleteSum = 0
  private type = ''
  private title = ''
  private detailBool = false
  private ids: Array<any> = []
  private total = 0
  private searchObj?: { keyword?: string }

  private isView = true
  private loading = false

  private roleList: OptionsRow[] = []

  private staffForm: UmsAdminParam = {
    icon: '',
    username: '',
    nickName: '',
    password: '',
    email: '',
    phone: '',
    roleIds: []
  }

  /**
   * @description 验证电邮格式
   * @author Jinx
   * @date 2021-08-17 10:05:50
   * @private
   * @param {*} rule
   * @param {*} value
   * @param {function} callback
   * @memberof CompanyStaff
   */
  private emailValidate = (rule, value, callback): void => {
    if(!value) {
      callback(new Error('电邮不能为空'))
    } else if (!isEmail(value)) {
      callback(new Error('请检查电邮格式是否正确'))
    } else {
      callback()
    }
  }

  private formRules: any = {
    username: [
      { required: true, message: '请输入登录账号'}
    ],
    password: [
      { required: true, message: '请输入密码' }
    ],
    email: [
      { required: true, validator: this.emailValidate }
    ]
  }

  public tableData: Array<any> = []

  private tableList: Array<TableRow> = [
    { label: '员工名称', prop: 'nickName' },
    { label: '登陆账号', prop: 'username' },
    { label: '手机号', prop: 'phone' },
    { label: '邮箱', prop: 'email' },
    { label: '角色', prop: 'rolesId', slot: {
      default: scope => <div>
        { scope.row.roleIds?.map(value => (
          <span style="padding: 0 5px;">{ this.roleList.find(item => item.value === value)?.name }</span>
        )) }
      </div>
    } },
    { label: '操作', slot: {
      default: scope => <div>
        <span class="span-button-primary" onClick={ () => this.staffEdit(scope.row) }>编辑</span>
        <span class="span-button-danger" onClick={ () => this.staffDelete(scope.row.id) }>删除</span>
      </div>
    } }
  ]

  searchList: Array<SearchListRow> = [
    { label: '员工名称', type: 'input', submitKey: 'keyword'  }
  ]
  buttonList: Array<ButtonListRow> = [
    { title: '添加', icon: 'el-icon-plus', click: 'staffAdd' },
    { title: '删除', icon: 'el-icon-minus', type: 'danger', click: 'staffDelete' },
  ]

  /**
   * @description 按钮点击
   * @author Jinx
   * @date 2021-08-16 18:36:18
   * @param {string} click
   * @memberof CompanyStaff
   */
  buttonClick(click: string):void {
    this[click]()
  }

  /**
   * @description 添加员工
   * @author Jinx
   * @date 2021-08-16 18:50:34
   * @memberof CompanyStaff
   */
  staffAdd(): void {
    this.type = 'add'
    this.detailBool = true
  }

  /**
   * @description 编辑员工
   * @author Jinx
   * @date 2021-08-17 13:49:53
   * @memberof CompanyStaff
   */
  staffEdit(row: UmsAdminParam): void {
    this.staffForm = Object.assign({}, row)
    this.type = 'edit',
    this.detailBool = true
  }

  /**
   * @description 删除员工
   * @author Jinx
   * @date 2021-08-16 18:50:38
   * @memberof CompanyStaff
   */
  staffDelete(id?: number[]): void {
    const ids = id ? [id] : this.ids
    if(!ids.length) {
      ElNotification({
        type: 'warning',
        message: '请选择要删除员工列表'
      })
      return
    }
    ElMessageBox.confirm('确定删除该员工？', '删除员工', {
      confirmButtonText: '删除员工',
      type: 'warning'
    }).then(() => {
      api.adminDelete(ids).then(() => {
        ElNotification({
          title: '成功',
          type: 'success',
          message: '删除员工成功'
        })
        id ? this.deleteSum++ : this.getList()
      })
    }).catch(err => {
      return err
    })
  }

  /**
   * @description 选择员工操作
   * @author Jinx
   * @date 2021-08-16 18:50:45
   * @param {Array<any>} row
   * @memberof CompanyStaff
   */
  selectionChange(row: Array<any>): void {
    this.ids = row.map(item => item.id)
  }

  /**
   * @description 关闭对话框
   * @author Jinx
   * @date 2021-08-17 09:31:50
   * @memberof CompanyStaff
   */
  detailClose(): void {
    this.type = ''
    this.detailBool = false
    this.staffForm = {
      icon: '',
      username: '',
      nickName: '',
      password: '',
      email: '',
      phone: '',
      roleIds: []
    }
  }

  /**
   * @description 获取员工列表
   * @author Jinx
   * @date 2021-08-16 18:50:57
   * @memberof CompanyStaff
   */
  getList(): void {
    const __pagination = this.$refs.pagination as any
    const { pageNum, pageSize } = __pagination.paginationObj as PaginationRow

    api.adminList({ pageNum, pageSize, ...this.searchObj }).then(res => {
      this.tableData = res.data.list
      this.total = res.data.total
    })
  }

  /**
   * @description 获取所有角色列表
   * @author Jinx
   * @date 2021-08-17 10:09:13
   * @memberof CompanyStaff
   */
  getRoleList(): void {
    api.roleListAll().then(res => {
      this.roleList = res.data.map(item => ({ name: item.name, value: item.id }))
    })
  }

  /**
   * @description 上传图片
   * @author Jinx
   * @date 2021-08-17 10:58:37
   * @memberof CompanyStaff
   */
  upLoadImg(): void {
    getFile(files => {
      this.loading = true
      const formData = new FormData()
      formData.append('file', files[0])

      api.commonOssUpload(formData).then(res => {
        this.staffForm.icon = res.data
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    },'image/*')
  }

  created(): void {
    this.getRoleList()
  }

  staffDetailDom(): JSX.Element {
    const slots = {
      // 对话框底部元素
      footer: () => (
        <span class="dialog-footer">
          <button class="dialog-footer-btn dialog-footer-btn-l" onClick={ this.detailClose } >取 消</button>
          <button class="dialog-footer-btn dialog-footer-btn-r" onClick={ () => debounceClick(this.submit) }>确 定</button>
        </span>
      )
    }

    const inputSlot = {
      // 输入框后面元素
      suffix: () => <i
        class={[
          'el-input__icon pointer',
          this.isView ? 'el-icon-view' : 'el-icon-lock'
        ]}
        onClick={() => this.isView = !this.isView}
      />
    }

    return (
      <el-dialog
        title={ `${this.title}员工` }
        v-model={ this.detailBool }
        width="30%"
        before-close={ this.detailClose }
        v-slots={ slots }
      >
        <el-form
          ref="form"
          model={ this.staffForm }
          rules={ this.formRules }
          label-width="120px"
          class="el-form-tao"
        >
          <el-form-item label="头像" prop="icon">
            {
              this.staffForm.icon
                ? <img src={ this.staffForm.icon } class={this.staffForm.icon ? 'icon-img' : 'company-content__img'} />
                : <i class={['company-content__icon', `el-icon-${this.loading ? 'loading' : 'picture'}`]}/>
            }
            <span class="company-content__upload pointer" onClick={ this.upLoadImg }>上传图片</span>
          </el-form-item>
          <el-form-item label="员工名称" prop="nickName">
            <el-input v-model={ this.staffForm.nickName } placeholder="请输入员工名称" clearable />
          </el-form-item>
          <el-form-item label="登录账号" prop="username">
            <el-input v-model={ this.staffForm.username } placeholder="请输入登录账号" clearable  />
          </el-form-item>
          <el-form-item label="登录密码" prop="password">
            <el-input
              type={ this.isView ? 'password' : 'text' }
              v-model={ this.staffForm.password }
              placeholder="请输入登录密码"
              clearable
              v-slots={ this.staffForm.password && inputSlot }
            />
          </el-form-item>
          <el-form-item label="电邮" prop="email">
            <el-input v-model={ this.staffForm.email } placeholder="请输入电邮" clearable  />
          </el-form-item>
          <el-form-item label="手机号" prop="phone">
            <el-input v-model={ this.staffForm.phone } placeholder="请输入手机号" clearable  />
          </el-form-item>
          <el-form-item label="角色" prop="roleIds">
            <el-select v-model={ this.staffForm.roleIds } class="el-select__multiple" placeholder="请输入角色" multiple clearable style="width: 100%" >
              { this.roleList.map(item => (
                <el-option key={ item.value } label={ item.name } value={ item.value } />
              )) }
            </el-select>
          </el-form-item>
        </el-form>
      </el-dialog>
    )

  }

  /**
   * @description 提交表单
   * @author Jinx
   * @date 2021-08-17 11:22:06
   * @memberof CompanyStaff
   */
  submit(): void {
    const __form = this.$refs.form as any
    __form.validate(v => {
      if(v) {
        const form = Object.assign({}, this.staffForm)
        // form.password = md5(form.password) // 简单做个MD5 加密

        this.type === 'add'
          ? this.submitAdd(form)
          : this.submitEdit(form)
      } else {
        return false
      }
    })
  }

  /**
   * @description 新增员工
   * @author Jinx
   * @date 2021-08-17 11:22:15
   * @param {UmsAdminParam} form
   * @memberof CompanyStaff
   */
  submitAdd(form: UmsAdminParam): void {
    delete form.id
    api.adminRgister(form).then(() => {
      ElNotification({
        title: '成功',
        type: 'success',
        message: '员工添加成功'
      })
      this.detailClose()
      this.getList()
    })
  }

  /**
   * @description 编辑员工
   * @author Jinx
   * @date 2021-08-17 14:11:05
   * @param {UmsAdminParam} form
   * @memberof CompanyStaff
   */
  submitEdit(form: UmsAdminParam): void {
    api.adminUpdate(form.id as number , form).then(() => {
      ElNotification({
        title: '成功',
        type: 'success',
        message: '员工信息编辑成功'
      })
      this.detailClose()
      this.getList()
    })
  }

  render(): JSX.Element {
    return <div class="company-staff">
      <Search list={ this.searchList } onSearch={ this.getList }/>
      <Button list={ this.buttonList } onClick={ this.buttonClick } />
      <el-table
        data={ this.tableData }
        onSelectionChange={ this.selectionChange }
      >
        <el-table-column type="selection" width="55" />
        {this.tableList.map(item => (
          <el-table-column
            label={ item.label }
            prop={ item.prop }
            align={ item.align || 'center' }
            v-slots={ item.slot }
          />
        ))}
      </el-table>
      <Pagination ref="pagination" total={ this.total } onCurrentChange={ this.getList } onSizeChange={ this.getList } />
      {this.detailBool && this.staffDetailDom()}
    </div>
  }

  mounted(): void {
    this.getList()
  }
}
