/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* 网页管理 */

import Website from '@/views/website/index.vue'

const First = () => import('@/views/website/first/index')
const Space = () => import('@/views/website/space/index')

import { RouteRecordRaw } from "vue-router";

const websiteRoute: RouteRecordRaw = {
  path: '/website',
  component: Website,
  name: 'Website',
  redirect: '/website/first',
  meta: { title: '网页管理', icon: 'website', roles: ['网页管理'] },
  children: [
    {
      path: 'first',
      component: First,
      name: 'First',
      meta: { title: '首页管理', roles: ['网页管理'] },
    },
    {
      path: 'space',
      component: Space,
      name: 'Space',
      meta: { title: '空间管理', roles: ['网页管理'] },
    }
  ]
}

export default websiteRoute
