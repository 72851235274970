import api from "@/api";
import Button from "@/components/Button";
import { SysSelectTypeOption } from "@/models/select";
import { ButtonListRow, TableRow } from "@/models/type";
import { debounceClick, typeWatch } from "@/utils";
import { ElMessageBox, ElNotification } from "element-plus";
import { Options, Vue } from "vue-class-component";

@Options({
  watch: {
    type(val?: string): void {
      this.title = typeWatch(val)
    },
    deteleSum(sum: number): void {
      if(sum) {
        this.getList()
      }
    }
  }
})
export default class BaseClass extends Vue {

  deteleSum = 0
  title = ''
  type = ''

  classForm: SysSelectTypeOption = {
    value: 0,
    typeId: 3,
    name: ''
  }
  formRules: any = {
    leftValue: [
      { required: true, message: '请选择起始课程类型', targger: 'change' }
    ],
    rightValue: [
      { required: true, message: '请选择结束课程类型', targger: 'change' }
    ]
  }
  ids: Array<number> = []
  detailBool = false
  private buttonList: Array<ButtonListRow> = [
    { title: '添加', icon: 'el-icon-plus', click: 'monthAdd' },
    { title: '删除', icon: 'el-icon-minus', type: 'danger', click: 'monthDel' }
  ]

  public total = 0

  private tableList: Array<TableRow> = [
    { label: '序号', slot: {
      default: (scope: any): JSX.Element => <span>{ scope.$index + 1 }</span>
    } },
    { label: '课程类型', prop: 'name' },
    { label: '操作', slot: {
      default: (scope: any): JSX.Element => <div>
        <span class="span-button-primary" onClick={ () => this.monthEdit(scope.row) }>编辑</span>
        <span class="span-button-danger" onClick={ () => this.monthDel(scope.row.id) }>删除</span>
      </div>
    } }
  ]

  tableData: Array<any> = []

  buttonClick(click: string): void {
    this[click]()
  }

  monthEdit(row: SysSelectTypeOption): void {
    const form = Object.assign({}, row)
    this.classForm = form
    this.type = 'edit'
    this.detailBool = true
  }

  monthAdd(): void {
    this.type = 'add'
    this.detailBool = true
  }

  monthDel(id: number): void {
    const ids = id ? [id] : this.ids
    if(!(ids.length > 0)) {
      ElNotification({
        type: 'warning',
        message: '请选择要删除的课程类型列表'
      })
      return
    }
    ElMessageBox.confirm('确定删除该课程类型？', '删除课程类型', {
      type: 'warning'
    }).then(() => {
      api.selectDeleteSysSelectTypeOption(ids).then(() => {
        ElNotification({
          title: '成功',
          type: 'success',
          message: '删除课程类型成功'
        })
        id ? this.deteleSum++ : this.getList()
      })
    }).catch(err => {
      return err
    })
  }

  getList(): void {
    api.selectGetSysSelectTypeOption(3).then(res => {
      this.tableData = res.data
    })
  }

  tableDom(): JSX.Element {
    return (
      <el-table
        data={ this.tableData }
        onSelectionChange={ this.selectionChange }
      >
        <el-table-column type="selection" width="55" />
        {this.tableList.map(item => (
          <el-table-column
          prop={ item.prop }
          label={ item.label }
          align={ item.align || 'center' }
          v-slots={ item.slot }
        />
        ))}
      </el-table>
    )
  }

  selectionChange(row: Array<SysSelectTypeOption>): void {
    this.ids = row.map(item => item.id) as Array<number>
  }

  detailClose(): void {
    this.detailBool = false
    this.type = ''
    this.classForm = {
      value: 0,
      typeId: 3,
      name: ''
    }
  }

  detailDom(): JSX.Element {

    const slots: any = {
      footer: (): JSX.Element => (
        <span class="dialog-footer">
          <button class="dialog-footer-btn dialog-footer-btn-l" onClick={ this.detailClose } >取 消</button>
          <button class="dialog-footer-btn dialog-footer-btn-r" onClick={ () => debounceClick(this.submit) }>确 定</button>
        </span>
      )
    }

    return (
      <el-dialog
        title={ `${ this.title }课程类型` }
        v-model={ this.detailBool }
        before-close={ this.detailClose }
        width="30%"
        v-slots={ slots }
      >
        <el-form
          ref="form"
          model={ this.classForm }
          rules={ this.formRules }
          label-width="130px"
          class="el-form-tao"
        >
          <el-form-item label="课程类型" prop="name">
            <el-input v-model={ this.classForm.name } clearable placeholder="请输入课程类型名称" />
          </el-form-item>
          <el-form-item label="课程类型绑定值" prop="value">
            <el-input-number v-model={ this.classForm.value } clearable placeholder="请选择课程名称绑定值" style="width: 100%" min={0} />
          </el-form-item>
        </el-form>
      </el-dialog>
    )
  }

  submit(): void {
    const __form = this.$refs.form as any
    __form.validate(v => {
      if(v) {
        const form = Object.assign({}, this.classForm)
        this.type === 'add'
          ? this.submitAdd(form)
          : this.submitEdit(form)
      } else {
        return false
      }
    })
  }

  submitAdd(form: SysSelectTypeOption): void {
    delete form.id
    api.selectAddSysSelectTypeOption(form).then(() => {
      ElNotification({
        title: '成功',
        type: 'success',
        message: '新增课程类型成功'
      })
      this.detailClose()
      this.getList()
    })
  }

  submitEdit(form: SysSelectTypeOption): void {
    api.selectModifySysSelectTypeOption(form).then(() => {
      ElNotification({
        title: '成功',
        type: 'success',
        message: '编辑课程类型成功'
      })
      this.detailClose()
      this.getList()
    })
  }

  render(): JSX.Element {
    return (
      <div class="base-month">
        <Button list={ this.buttonList } onClick={ this.buttonClick } />
        {this.tableDom()}
        {this.detailBool && this.detailDom()}
      </div>
    )
  }

  mounted(): void {
    this.getList()
  }
}
