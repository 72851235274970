import select from './modules/select'
import admin from './modules/admin'
import oauth from './modules/oauth'
import home from './modules/home'
import common from './modules/common'
import role from './modules/role'
import menu from './modules/menu'
import resource from './modules/resource'
import subject from './modules/subject'
import activity from './modules/activity'
import good from './modules/good'
import productCategory from './modules/productCategory'
import product from './modules/product'
import order from './modules/order'
import coupon from './modules/coupon'
import team from './modules/team'
import system from './modules/system'
import memberLevel from './modules/memberLevel'
import comment from './modules/comment'

const api = {
  ...select,
  ...admin,
  ...oauth,
  ...home,
  ...common,
  ...role,
  ...menu,
  ...resource,
  ...subject,
  ...activity,
  ...good,
  ...productCategory,
  ...product,
  ...order,
  ...coupon,
  ...team,
  ...system,
  ...memberLevel,
  ...comment
}

export default api
