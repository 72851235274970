import api from "@/api";
import Button from "@/components/Button";
import Card from "@/components/Card";
import Pagination from "@/components/Pagination";
import Search from "@/components/Search";
import { SmsGoodWork } from "@/models/good";
import { smsHomeImgStatus } from "@/models/home";
import { ButtonListRow, OptionsRow, PaginationRow, SearchListRow, TableRow } from "@/models/type";
import { debounceClick, getFile, typeWatch } from "@/utils";
import { isEmail } from "@/utils/validate";
import { ElMessageBox, ElNotification } from "element-plus";
import { Options, Vue } from "vue-class-component";
import './index.scss'

@Options({
  watch: {
    type(val?: string): void {
      this.title = typeWatch(val)
    },
    swithSum(sum: number): void {
      if(sum) {
        this.getList()
      }
    }
  }
})
export default class Good extends Vue {

  private swithSum = 0

  private emailValidate = (rule, value, callback): void => {
    if(!value) {
      callback(new Error('邮箱不能为空'))
    } else if (!isEmail(value)) {
      callback(new Error('请检查邮箱格式是否正确'))
    } else {
      callback()
    }
  }

  private searchList: Array<SearchListRow> = []

  private buttonList: Array<ButtonListRow> = [
    { title: '添加', icon: 'el-icon-plus', click: 'goodAdd' },
  ]

  private typeList: Array<OptionsRow> = []

  private total = 0
  private detailBool = false
  private loading = false
  private title = ''
  private type = ''

  public goodForm: SmsGoodWork = {
    title: '',
    introduce: '',
    imgPath: '',
    type: undefined,
    author: ''
  }

  private formRules: any = {
    title: [
      { required: true, message: '请输入作品姓名' }
    ],
    phone: [
      { required: true, message: '请输入作品手机号' }
    ],
    subjectType: [
      { required: true, message: '请选择课程类型', trigger: 'change' }
    ],
    email: [
      { required: true, validator: this.emailValidate }
    ]
  }

  private searchObj: any = {}

  private url = ''
  private srcList: Array<string> = []

  public tableData: Array<any> = []
  public tableList: Array<TableRow> = [
    { label: '序号', slot: {
      default: (scope: any): JSX.Element => <span>{ scope.$index + 1 }</span>
    } },
    { label: '作品名称', prop: 'title' },
    { label: '作品类型', slot: {
      default: (scope: any): JSX.Element => <span>{ this.typeList.find(item => item.value === scope.row.type)?.name }</span>
    } },
    { label: '作品作者', prop: 'author' },
    { label: '操作', slot: {
      default: (scope: any): JSX.Element => <div>
        <span class="span-button-primary" onClick={ () => this.goodEdit(scope.row) }>编辑</span>
        <span class="span-button-danger" onClick={ () => this.goodDelete(scope.row.id, scope.$index) }>删除</span>
      </div>
    } },
  ]


  buttonClick(click: string): void {
    this[click]()
  }

  goodAdd(): void {
    this.type = 'add'
    this.detailBool = true
  }

  goodEdit(row: SmsGoodWork): void {
    this.goodForm = Object.assign({}, row)
    this.type = 'edit'
    this.detailBool = true
  }

  goodDelete(id: number, index: number): void {
    ElMessageBox.confirm('确定删除该作品信息？', '删除作品', {
      type: 'warning'
    }).then(() => {
      api.goodworkDeleteGoodWork([id]).then(() => {
        ElNotification({
          title: '成功',
          type: 'success',
          message: '删除作品成功'
        })
        this.tableData.splice(index, 1)
      })
    }).catch(err => {
      return err
    })
  }

  getList(): void {
    const __pagination = this.$refs.pagination as any
    const { pageNum, pageSize } = __pagination.paginationObj as PaginationRow

    api.goodworkGetGoodWorkList({ pageNum, pageSize, ...this.searchObj }).then(res => {
      this.tableData = res.data.list
      this.total = res.data.total
    })
  }

  getData(): void {
    Promise.all([
      api.selectGetSysSelectTypeOption(4)
    ]).then(res => {
      this.typeList = res[0].data.map(item => ({ name: item.name, value: item.value }))
      this.searchList = [
        { label: '作品名称', type: 'input', submitKey: 'title', placeholder: '请输入作品名称' },
        { label: '作品类型', type: 'select', submitKey: 'type', options: this.typeList, placeholder: '请选择作品类型' },
        { label: '作品作者', type: 'input', submitKey: 'author', placeholder: '请输入作品作者' },
      ]
    })
  }

  tableDom(): JSX.Element {
    return (
      <el-table
        ref="table"
        data={ this.tableData }
      >
        {this.tableList.map(item => (
          <el-table-column
          prop={ item.prop }
          label={ item.label }
          align={ item.align || 'center' }
          v-slots={ item.slot }
        />
        ))}
      </el-table>
    )
  }

  onSearch(obj: never): void {
    this.searchObj = Object.assign({}, obj)
    this.getList()
  }


  detailDom(): JSX.Element {
    const slots: any = {
      footer: (): JSX.Element => (
        <span class="dialog-footer">
          <button class="dialog-footer-btn dialog-footer-btn-l" onClick={ this.detailClose } >取 消</button>
          <button class="dialog-footer-btn dialog-footer-btn-r" onClick={ () => debounceClick(this.submit) }>确 定</button>
        </span>
      )
    }

    return (
      <el-dialog
        title={ `${ this.title }作品` }
        v-model={ this.detailBool }
        before-close={ this.detailClose }
        width="30%"
        v-slots={ slots }
      >
        <el-form
          ref="form"
          model={ this.goodForm }
          rules={ this.formRules }
          label-width="120px"
          class="el-form-tao"
        >
          <el-form-item label="名称" prop="title">
            <el-input v-model={ this.goodForm.title } clearable placeholder="请输入作品名称" />
          </el-form-item>
          <el-form-item label="类型" prop="type">
            <el-select v-model={ this.goodForm.type } clearable placeholder="请选择作品类型" style="width: 100%">
              {this.typeList.map(item => (
                <el-option label={ item.name } value={ item.value } />
              ))}
            </el-select>
          </el-form-item>
          <el-form-item label="作者" prop="author">
            <el-input v-model={ this.goodForm.author } clearable placeholder="请输入作品作者" />
          </el-form-item>
          <el-form-item label="简介" prop="introduce">
            <el-input type="textarea" v-model={ this.goodForm.introduce } clearable placeholder="请输入作品简介" max={120} show-word-limit />
          </el-form-item>
          <el-form-item label="作品" prop="imgPath">
            <div>
              { this.loading
                ? <span class="el-form-tao-btn" >上传中...</span>
                : <span class="el-form-tao-btn" onClick={ this.uploadFile }>{ this.goodForm.imgPath ? '重新上传' : '上传' }</span>}
              {this.goodForm.imgPath && <span class="el-form-tao-view" onClick={this.someView} >预览</span>}
            </div>
          </el-form-item>
        </el-form>
      </el-dialog>
    )
  }

  someView(): void {
    const url = this.goodForm.imgPath as string
    this.url = url
    this.srcList = [url]
    this.imgViewerShow()
  }

  imgViewerShow(): void {
    const __img =  this.$refs.img as any
    __img.showViewer = true
  }

  imgViewerDom(): JSX.Element {
    return <el-image
      ref="img"
      style="width: 0; height: 0; z-index: 54188;"
      src={ this.url }
      preview-src-list={ this.srcList }
    >
    </el-image>
  }

  uploadFile(): void {
    getFile(files => {
      if(!files.length) {
        return
      }
      const formData = new FormData()
      formData.append('file', files[0])
      this.loading = true
      api.commonOssUpload(formData).then(res => {
        this.goodForm.imgPath = res.data
        ElNotification({
          title: '成功',
          type: 'success',
          message: `上传照片成功`
        })
        this.loading = false
      })
    }, 'WORK', 'image/*')
  }

  submit(): void {
    const __form = this.$refs.form as any
    __form.validate(v => {
      if (v) {
        const form = Object.assign({}, this.goodForm)
        this.type === 'add'
          ? this.submitAdd(form)
          : this.submitEdit(form)
      } else {
        return false
      }
    })
  }

  submitAdd(form: SmsGoodWork): void {
    delete form.id
    api.goodworkAddGoodWork(form).then(() => {
      ElNotification({
        title: '成功',
        type: 'success',
        message: '添加作品成功'
      })
      this.detailClose()
      this.getList()
    })
  }

  submitEdit(form: SmsGoodWork): void {
    api.goodworkEditGoodWork(form).then(() => {
      ElNotification({
        title: '成功',
        type: 'success',
        message: '编辑作品成功'
      })
      this.detailClose()
      this.getList()
    })
  }

  detailClose(): void {
    this.detailBool = false
    this.type = ''
    this.goodForm = {
      title: '',
      introduce: '',
      imgPath: '',
      type: undefined,
      author: ''
    }
  }

  created(): void {
    this.getData()
  }

  render(): JSX.Element {
    return (
      <div class="good">
        <Card title="作品查询" class="good-card">
          <Search list={ this.searchList } class="good-search" onSearch={ this.onSearch } />
        </Card>
        <Card title="作品管理">
          <div class="good-search">
            <Button list={ this.buttonList } onClick={ this.buttonClick } />
            {this.tableDom()}
            <Pagination ref="pagination" total={ this.total } onCurrentChange={ this.getList } onSizeChange={ this.getList } />
          </div>
        </Card>
        {this.imgViewerDom()}
        {this.detailBool && this.detailDom()}
      </div>
    )
  }

  mounted(): void {
    this.getList()
  }
}
