
const req = require.context('./svg', false, /\.svg$/)
const svgFiles = require.context('./svg', true, /\.svg$/)
const iconList = svgFiles.keys().map(item => svgFiles(item))
const requireAll = requireContext => requireContext.keys().map(requireContext)
requireAll(req)

export default {
  // 获取图标icon-(*).svg名称列表, 例如[shouye, xitong, zhedie, ...]
  getNameList(): Array<any> {
    return iconList.map(item => item.default.id.split('-')[1])
  }
}
