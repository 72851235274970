/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { RouteRecordRaw } from "vue-router";
import Mall from '@/views/mall/index.vue'
const Commodity = () => import('@/views/mall/commodity')
const Integral = () => import('@/views/mall/integral')
const Comment = () => import('@/views/mall/comment')
const Order = () => import('@/views/mall/order')

const mallRoute: RouteRecordRaw = {
  path: '/mall',
  name: 'Mall',
  component: Mall,
  redirect: 'mall/commodity',
  meta: { title: '商城管理', icon: 'mall', roles: ['商城管理'] },
  children: [
    {
      path: 'commodity',
      component: Commodity,
      meta: { title: '商城商品', roles: ['商城管理'] }
    },
    {
      path: 'integral',
      component: Integral,
      meta: { title: '积分商品', roles: ['商城管理'] }
    },
    {
      path: 'comment',
      component: Comment,
      meta: { title: '评论管理', roles: ['商城管理'] }
    },
    {
      path: 'order',
      component: Order,
      name: 'MallOrder',
      meta: { title: '订单管理', roles: ['商城管理'] }
    }
  ]
}

export default mallRoute
