import Tabs from "@/components/Tabs";
import { TabListRow } from "@/models/type";
import { UserStoreRow } from "@/store/modules/user";
import { Options, Vue } from "vue-class-component";
import { mapState } from "vuex";
import CompanyMenu from "./components/companyMenu";
import CompanyMine from "./components/companyMine";
import CompanyResource from "./components/companyResource";
import CompanyRole from "./components/companyRole";
import CompanyStaff from "./components/companyStaff";
import './index.scss'

/**
 * @description 公司管理
 * @author Jinx
 * @date 2021-08-13 11:35:26
 * @export
 * @class Company
 * @extends {Vue}
 */
@Options({
  components: {
    CompanyMine, CompanyRole, CompanyStaff, CompanyMenu, CompanyResource
  },
  computed: {
    ... mapState(['user'])
  },
  watch: {
    '$route.query': {
      deep: true,
      immediate: true,
      handler(val) {
        if(val.activeName) {
          this.activeName = 'mine'
          this.$router.push({ path: '/company' })
        }
      }
    }
  }
})
export default class Company extends Vue {

  public user?: UserStoreRow

  public tabList: Array<TabListRow> = [
    { title: '个人中心', activeName: 'mine' },
    { title: '角色列表', activeName: 'role' },
    { title: '员工列表', activeName: 'staff' },
    // { title: '菜单管理', activeName: 'menu' },
    // { title: '资源管理', activeName: 'resource' },
  ]

  public activeName = 'mine'

  public slots: any = {
    mine: () => <CompanyMine />,
    role: () => <CompanyRole />,
    staff: () => <CompanyStaff />,
    menu: () => <CompanyMenu />,
    resource: () => <CompanyResource />,
  }

  /**
   * @description 选中操作
   * @author Jinx
   * @date 2021-08-13 11:34:58
   * @param {string} activeName
   * @memberof Company
   */
  activeNameChange(activeName: string): void {
    this.activeName = activeName
  }

  /**
   * @description 个人信息
   * @author Jinx
   * @date 2021-08-13 11:35:05
   * @returns {*}  {JSX.Element}
   * @memberof Company
   */
  userInfo(): JSX.Element {
    return (
      <div class="company-info">
        <img src={ this.user?.icon } alt={ this.user?.name } />
        <div>
          <span>账号：{ this.user?.name }</span>
          <span>角色：{ this.user?.role }</span>
        </div>
      </div>
    )
  }

  /**
   * @description 内容标签页
   * @author Jinx
   * @date 2021-08-13 11:35:12
   * @returns {*}  {JSX.Element}
   * @memberof Company
   */
  content(): JSX.Element {
    return (
      <div class="company-content">
        <Tabs tabList={ this.tabList } activeName={ this.activeName } onActivechange={ this.activeNameChange } v-slots={ this.slots } />
      </div>
    )
  }

  render(): JSX.Element {
    return (
      <div class="company">
        <div>
          {this.userInfo()}
          {this.content()}
        </div>
      </div>
    )
  }

}
