import { RouteLinkBack } from "@/models/type";
import { isExternal } from "@/utils/validate";
import { computed, defineComponent, toRefs } from "vue";
import { RouterLink } from "vue-router";

const AppLink = defineComponent({
  props: {
    to: {
      type: String,
      required: true
    }
  },
  setup(props, {slots}){

    const { to } = toRefs(props)

    const externalBool = computed(() => isExternal(to.value))

    const type = computed(() => {
      if(externalBool.value) {
        return 'a'
      }
      return 'router-link'
    })

    function dynaComponent(){
      switch(type.value){
        case 'a':
          return <a v-bind={linkProps(to.value)}>{ slots.default && slots.default() }</a>
        case 'router-link':
          return <RouterLink {...linkProps(to.value)} >
            { slots.default && slots.default() }
          </RouterLink>
        default:
      }
    }

    function linkProps(to: string): RouteLinkBack {
      if(externalBool.value) {
        return {
          href: to,
          target: '_blank',
          rel: 'noopener',
          to
        }
      }
      return {
        to
      }
    }

    return () => dynaComponent()
  }
})

export default AppLink
