/* 公司管理 */
import Company from '@/views/company'

const companyRoute = {
  path: '/company',
  name: 'Company',
  component: Company,
  meta: { title: '公司管理', icon: 'company', roles: ['公司管理'] }
}

export default companyRoute
