
module.exports = {
  title: '道驛空間',

  fixedHeader: true, // 固定头部导航栏

  sidebarLogo: true, // 是否显示logo

  paginationObj: {
    pageSize: 10, // 每页多少条
    pageNum: 1, // 第几页
    pageSizes: [10, 20, 50, 100, 200]
  }
}
