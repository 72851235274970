import SvgIcon from "@/components/SvgIcon";
import { DropdownRow, FunRow } from "@/models/type";
import { UserStoreRow } from "@/store/modules/user";
import { Options, Vue } from "vue-class-component";
import { RouterLink } from "vue-router";
import { mapState } from "vuex";

@Options({
  computed: {
    ...mapState(['user'])
  }
})
export default class Navbar extends Vue {

  public user?: UserStoreRow

  private dropdown_icon = 'el-icon-caret-bottom' // 下拉图标

  private dropdown_list: Array<DropdownRow> = [
    { title: '个人中心' },
    { title: '修改密码' },
    { title: '退出登录' }
  ]

  private fun_list: Array<FunRow> = [
    { icon: 'fun_1', to: '/home' },
    { icon: 'fun_2', to: '/home1' },
    { icon: 'fun_3', to: '/home2' },
    { icon: 'fun_4', to: '/home3' },
  ]

  /**
   * @description 功能dom
   * @author Jinx
   * @date 2021-50-09 14:08:14
   * @private
   * @returns {*}  {JSX.Element}
   * @memberof Navbar
   */
  private funDom(): JSX.Element {
    return(
      <div class="navbar-fun">
        {this.fun_list.map(i => (
          <el-badge is-dot={ i.isDot } class="navbar-fun__badge">
            <RouterLink to={ i.to } activeClass="navbar-fun__active">
              <SvgIcon iconClass={ i.icon }/>
            </RouterLink>
          </el-badge>
        ))}
      </div>
    )
  }

  /**
   * @description 右侧dom
   * @author Jinx
   * @date 2021-44-09 14:08:45
   * @private
   * @returns {*}  {JSX.Element}
   * @memberof Navbar
   */
  private rightDom(): JSX.Element {
    const slots = {
      dropdown: () => <el-dropdown-menu>
          {this.dropdown_list.map((i, ix) => (
            <el-dropdown-item icon={ i.icon } onClick={ this.handleDropdown.bind(this, ix) } >{ i.title }</el-dropdown-item>
          ))}
        </el-dropdown-menu>
    }
    return this.user?.icon || this.user?.name ? (
      <div class="navbar-right pointer">
        <img class="navbar-right__avatar" src={ this.user.icon } alt={ this.user.name } />
        <div class="navbar-right__set">
          <el-dropdown trigger="click" v-slots={ slots } onVisibleChange={ this.dropdownChange } >
          <span class="el-dropdown-link">
            { this.user.name }<i class={[`${this.dropdown_icon}`, 'navbar-right__icon'] }></i>
          </span>
          </el-dropdown>
        </div>
      </div>
    ) : (
      <i class="navbar-right-icon el-icon-loading"/>
    )
  }

  /**
   * @description 选择某一项的事件
   * @author Jinx
   * @date 2021-43-09 14:08:41
   * @private
   * @param {number} index
   * @memberof Navbar
   */
  private async handleDropdown(index: number): Promise<void> {
    switch (index) {
      case 0:
        // 个人中心
        this.$router.push({
          path: '/company',
          query: { activeName: 1 }
        })
        break
      case 2:
        // 登出
        await this.$store.dispatch('logout')
        this.$router.push(`/login?redirect=${this.$route.fullPath}`)
        break
      default:
        break
    }
  }

  /**
   * @description 根据状态改变图标样式
   * @author Jinx
   * @date 2021-43-09 14:08:24
   * @private
   * @param {boolean} isDown
   * @memberof Navbar
   */
  private dropdownChange(isDown): void {
    this.dropdown_icon = isDown ? 'el-icon-caret-top' : 'el-icon-caret-bottom'
  }

  render(): JSX.Element {
    return (
      <div class="navbar">
        {/* { this.funDom() } */}
        { this.rightDom() }
      </div>
    )
  }
}
