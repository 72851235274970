import Tabs from "@/components/Tabs";
import { TabListRow } from "@/models/type";
import { Options, Vue } from "vue-class-component";
import BaseClass from "./components/baseClass";
import BaseCommodify from "./components/baseCommodity";
import BaseMonth from "./components/baseMonth";
import BaseWork from "./components/baseWork";
import './index.scss'

/**
 * @description 基础设置
 * @author Jinx
 * @date 2021-08-19 16:33:30
 * @export
 * @class Base
 * @extends {Vue}
 */
@Options({
  components: { BaseCommodify, BaseWork, BaseClass, BaseMonth }
})
export default class Base extends Vue {

  private tabList: Array<TabListRow> = [
    { title: '商品类型', activeName: 'commodify' },
    { title: '作品类型', activeName: 'work' },
    { title: '课程类型', activeName: 'class' },
    { title: '月份设置', activeName: 'month' }
  ]

  private activeName = 'commodify'

  public slots: any = {
    commodify: () => <BaseCommodify />,
    work: () => <BaseWork />,
    class: () => <BaseClass />,
    month: () => <BaseMonth />,
  }

  content(): JSX.Element {
    return (
      <div class="base-content">
        <Tabs tabList={ this.tabList } activeName={ this.activeName } onActivechange={ this.activeNameChange } v-slots={ this.slots } />
      </div>
    )
  }

  activeNameChange(activeName: string): void {
    this.activeName = activeName
  }

  render(): JSX.Element {
    return (
      <div class="base">
        {this.content()}
      </div>
    )
  }
}
