
import SvgIcon from '@/components/SvgIcon'
import { RefAny } from '@/models/type'
import { defineComponent } from '@vue/runtime-core'
  export default defineComponent({
    components: {
      SvgIcon
    },
    data(){
      return {
        loading: false,
        isChecked: false,
        isShow: false,
        errorMessage: false,
        loginForm: {
          username: '',
          password: ''
        },
        formRules: {
          username: [
            { required: true, message: '请输入用户名' }
          ],
          password: [
            { required: true, message: '请输入密码' }
          ],
        },
        redirect: undefined,
        otherQuery: {}
      }
    },
    watch: {
      $route: {
        deep: true,
        immediate: true,
        handler: function (route) {
          const query = route.query
          if (query) {
            this.redirect = query.redirect
            this.otherQuery = this.getOtherQuery(query)
          }
        }
      },
    },
    mounted(){
      this.$nextTick(() => {
        this.getCheckedBool()
      })
    },
    methods: {
      getCheckedBool(){
        // 判断是否记住密码
        const checked = JSON.parse(window.localStorage.getItem('checked') as string)
        if(!checked) {
          return
        }
        if (checked.isChecked) {
          this.isChecked = true
          this.loginForm.username = checked.username
          this.loginForm.password = checked.password
        } else if (checked.username) {
          this.loginForm.username = checked.username
        } else {
          return false
        }
      },
      setCheckedBool(){
        // 提交用户名 密码
        if (this.isChecked) {
          const obj = {
            ...this.loginForm,
            isChecked: this.isChecked
          }
          window.localStorage.setItem('checked', JSON.stringify(obj))
        } else {
          const obj = {
            username: this.loginForm.username
          }
          window.localStorage.setItem('checked', JSON.stringify(obj))
        }
      },
      login() {
        // 登录
        const ref = this.$refs.form as RefAny
        ref.validate(async valid => {
          if (valid) {
            this.loading = true
            const loginStatus = await this.$store.dispatch('login', this.loginForm)
            if (loginStatus) {

              this.setCheckedBool()
              this.$router.push({ path: this.redirect || '/', query: this.otherQuery })
              this.loading = false

            } else {
              this.loading = false
            }
          } else {
            return false;
          }
        })
      },
      getOtherQuery(query){
        // 获取其他要跳转数据
        return Object.keys(query).reduce((acc, cur) => {
          if (cur !== 'redirect') {
            acc [cur] = query[cur]
          }
          return acc
        }, {})
      }
    }
  })
