import { Response } from "@/models/request"
import service from "@/api/request"
import { UmsAdminLoginParam, UmsAdminParam } from "@/models/admin"
import { RoleListParams } from "@/models/role"

// 用户管理
const api = {
  /**
   * @description  登录以后返回token
   * @author Jinx
   * @date 2021-09-11 16:08:35
   * @param {UmsAdminLoginParam} data
   * @returns {*}  {Response}
   */
  adminLogin(data: UmsAdminLoginParam): Promise<Response> {
    return service({
      url: '/admin/login',
      method: 'post',
      data
    })
  },

  /**
   * @description 获取当前登录用户信息
   * @author Jinx
   * @date 2021-57-12 09:08:50
   * @returns {*}  {Promise<Response>}
   */
  adminInfo(): Promise<Response> {
    return service({
      url: '/admin/info',
      method: 'get'
    })
  },

  /**
   * @description 登出功能
   * @author Jinx
   * @date 2021-13-12 10:08:31
   * @returns {*}  {Promise<Response>}
   */
  adminLogout(): Promise<Response> {
    return service({
      url: '/admin/logout',
      method: 'post'
    })
  },

  /**
   * @description 根据用户名或姓名分页获取用户列表
   * @author Jinx
   * @date 2021-08-16 18:50:12
   * @param {RoleListParams} params
   * @returns {*}  {Promise<Response>}
   */
  adminList(params: RoleListParams): Promise<Response> {
    return service({
      url: '/admin/list',
      method: 'get',
      params
    })
  },

  /**
   * @description 用户注册
   * @author Jinx
   * @date 2021-08-17 11:06:53
   * @param {UmsAdminParam} data
   * @returns {*}  {Promise<Response>}
   */
  adminRgister(data: UmsAdminParam): Promise<Response> {
    return service({
      url: '/admin/register',
      method: 'post',
      data
    })
  },

  /**
   * @description 修改指定用户信息
   * @author Jinx
   * @date 2021-08-17 14:09:38
   * @param {number} id
   * @param {UmsAdminParam} data
   * @returns {*}  {Promise<Response>}
   */
  adminUpdate(id: number, data: UmsAdminParam): Promise<Response> {
    return service({
      url: `/admin/update/${id}`,
      method: 'post',
      data
    })
  },

  /**
   * @description 删除指定用户信息
   * @author Jinx
   * @date 2021-08-17 16:32:33
   * @param {Array<number>} data
   * @returns {*}  {Promise<Response>}
   */
  adminDelete(data: Array<number>): Promise<Response> {
    return service({
      url: '/admin/delete',
      method: 'post',
      data
    })
  }
}

export default api
