import { Options, Vue } from "vue-class-component";
import { mapGetters } from "vuex";
import { AppMain, Navbar, Sidebar } from "./components";

@Options({
  components: {
    Navbar, Sidebar
  },
  computed: mapGetters(['GetFixedHeader'])
})
export default class Layout extends Vue {
  GetFixedHeader?: boolean

  render(): JSX.Element {
    const rightDom = <div class={{ 'fixed-header': this.GetFixedHeader }}><Navbar /></div>
    return (
      <>
        <Sidebar class="sidebar" />
        <div class="main-container">
          { rightDom }
          <AppMain class="app-main" />
        </div>
      </>
    )
  }
}
