/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { RouteRecordRaw } from "vue-router";
import Statistics from '@/views/statistics/index.vue'

const StatisticsOrder = () => import('@/views/statistics/order')
const StatisticsSignup = () => import('@/views/statistics/signup')



/* 报表管理 */
const statisticsRoute: RouteRecordRaw = {
  path: '/statistics',
  component: Statistics,
  name: 'Statistics',
  redirect: '/statistics/classing',
  meta: { title: '报表管理', icon: 'statistics', roles: ['报表管理'] },
  children: [
    {
      path: 'order',
      component: StatisticsOrder,
      name: 'StatisticsOrder',
      meta: { title: '订单统计', roles: ['报表管理'] },
    },
    {
      path: 'signup',
      component: StatisticsSignup,
      name: 'StatisticsSignup',
      meta: { title: '报名统计', roles: ['报表管理'] },
    }
  ]
}

export default statisticsRoute
