import { constantRoutes } from "@/router"
import { generatorDynamicRouter } from "@/router/generator-routers"
import { RouteRecordRaw } from "vue-router"
import { ActionContext } from "vuex"

interface AsyncRouterStoreRow {
  routers: Array<RouteRecordRaw>
  addRouters: Array<any>
}

const asyncRouterStore = {
  state: {
    routers: constantRoutes,
    addRouters: []
  } as AsyncRouterStoreRow,

  mutations: {
    SET_ROUTERS: (state: AsyncRouterStoreRow, routers: Array<RouteRecordRaw>): void => {
      state.addRouters = routers
      state.routers = constantRoutes.concat(routers)
    }
  },
  actions: {
    generateRoutesAsync ({ commit }: ActionContext<AsyncRouterStoreRow, AsyncRouterStoreRow>, data: Array<any>): Promise<any> {
      return new Promise(resolve => {
        generatorDynamicRouter(data).then(routers => {
          commit('SET_ROUTERS', routers)
          resolve(true)
        })
      })
    }
  }
}

export default asyncRouterStore
