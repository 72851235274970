
import { Cb } from "@/models/type"
import { asyncRoutes, constantRoutes } from "@/router"
import { RouteRecordRaw } from "vue-router"
import { ActionContext } from "vuex"

/**
 * @description 检查权限
 * @author Jinx
 * @date 2021-08-06 10:16:18
 * @param {*} roles
 * @param {*} route
 * @returns {*}  {boolean}
 */
function hasPermission(roles, route): boolean {
  if (route.meta && route.meta.roles) {
    return roles.some(role => route.meta.roles.includes(role))
  } else {
    return true
  }
}

/**
 * @description 过滤动态路由
 * @author Jinx
 * @date 2021-08-06 10:15:50
 * @export
 * @param {*} routes
 * @param {*} roles
 * @returns {*}
 */
export function filterAsyncRoutes(routes: Array<RouteRecordRaw>, roles: Array<string> ): Array<RouteRecordRaw> {
  const res = []

  routes.forEach(route => {
    const tmp = { ...route }
    if (hasPermission(roles, tmp)) {
      if (tmp.children) {
        tmp.children = filterAsyncRoutes(tmp.children, roles)
      }
      res.push(tmp as never)
    }
  })

  return res
}
interface PermissionStoreRow {
  routes?: Array<RouteRecordRaw>,
  addRoutes?: Array<RouteRecordRaw>
}


const permissionStore = {
  state: {
    routes: [],
    addRoutes: []
  } as PermissionStoreRow,

  mutations: {
    SET_ROUTES: (state: PermissionStoreRow, routes: Array<RouteRecordRaw> ) :void => {
      state.addRoutes = routes
      state.routes = constantRoutes.concat(routes)
    }
  },

  actions: {
    /**
     * @description 返回最终路由
     * @author Jinx
     * @date 2021-08-06 10:16:36
     * @param {*} { commit }
     * @param {*} roles
     * @returns {*}
     */
    generateRoutes({ commit }: ActionContext<PermissionStoreRow, PermissionStoreRow>, menu: Array<any>): Promise<Array<RouteRecordRaw>> {
      return new Promise(resolve => {
        /* let accessedRoutes

        if (roles.includes('超级管理员')) {
          accessedRoutes = asyncRoutes || []
        } else {
          accessedRoutes = filterAsyncRoutes(asyncRoutes, roles)
        } */
        const menuArr = menu.map(item => item.title)
        const accessedRoutes = filterAsyncRoutes(asyncRoutes, menuArr)
        commit('SET_ROUTES', accessedRoutes)
        resolve(accessedRoutes)
      })
    }
  },

  getters: {
    GetPermissionRoutes: (state): Cb => state.routes
  }
}

export default permissionStore
