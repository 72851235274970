import { Response } from "@/models/request"
import service from "@/api/request"
import { PaginationRow } from "@/models/type"
import { SmsHomeImg, SmsHomeSubject, SysCooperation, SysInstitution, SysPropertiesItemList, SysTeam } from "@/models/home"

const api = {
  /**
   * @description 获取课程时间表列表
   * @author Jinx
   * @date 2021-04-12 17:08:23
   * @param {PaginationRow} params
   * @returns {*}  {Promise<Response>}
   */
  homeSubjectGetHomeSubjectList(params: PaginationRow): Promise<Response> {
    return service({
      url: '/home/subject/getHomeSubjectList',
      method: 'get',
      params
    })
  },

  /**
   * @description 获取首页图片列表
   * @author Jinx
   * @date 2021-05-12 17:08:35
   * @param {PaginationRow} params
   * @returns {*}  {Promise<Response>}
   */
  homeImgGetHomeImgList(params: PaginationRow): Promise<Response> {
    return service({
      url: '/home/img/getHomeImgList',
      method: 'get',
      params
    })
  },

  /**
   * @description 添加首页图片
   * @author Jinx
   * @date 2021-08-19 10:55:48
   * @param {SmsHomeImg} data
   * @returns {*}  {Promise<Response>}
   */
  homeImgAddHomeImg(data: SmsHomeImg): Promise<Response> {
    return service({
      url: '/home/img/addHomeImg',
      method: 'post',
      data
    })
  },

  /**
   * @description 编辑首页图片
   * @author Jinx
   * @date 2021-08-19 10:56:17
   * @param {SmsHomeImg} data
   * @returns {*}  {Promise<Response>}
   */
  homeImgEditHomeImg(data: SmsHomeImg): Promise<Response> {
    return service({
      url: '/home/img/editHomeImg',
      method: 'post',
      data
    })
  },

  /**
   * @description 删除首页图片
   * @author Jinx
   * @date 2021-08-19 10:56:47
   * @param {Array<number>} data
   * @returns {*}  {Promise<Response>}
   */
  homeImgDeleteHomeImg(data: Array<number>): Promise<Response> {
    return service({
      url: '/home/img/deleteHomeImg',
      method: 'post',
      data
    })
  },

  /**
   * @description 设置默认展示项
   * @author Jinx
   * @date 2021-08-19 14:59:27
   * @param {number} id
   * @returns {*}  {Promise<Response>}
   */
  homeImgSetStatus(id: number): Promise<Response> {
    return service({
      url: '/home/img/setStatus',
      method: 'put',
      params: { id }
    })
  },

  /**
   * @description 添加课程时间表
   * @author Jinx
   * @date 2021-08-19 15:39:19
   * @param {SmsHomeSubject} data
   * @returns {*}  {Promise<Response>}
   */
  homeSubjectAddHomeSubject(data: SmsHomeSubject): Promise<Response> {
    return service({
      url: '/home/subject/addHomeSubject',
      method: 'post',
      data,
    })
  },

  /**
   * @description 编辑课程时间表
   * @author Jinx
   * @date 2021-08-19 15:39:22
   * @param {SmsHomeSubject} data
   * @returns {*}  {Promise<Response>}
   */
  homeSubjectEditHomeSubject(data: SmsHomeSubject): Promise<Response> {
    return service({
      url: '/home/subject/editHomeSubject',
      method: 'post',
      data,
    })
  },

  /**
   * @description 删除课程时间表
   * @author Jinx
   * @date 2021-08-19 15:39:24
   * @param {Array<number>} data
   * @returns {*}  {Promise<Response>}
   */
  homeSubjectDeleteHomeSubject(data: Array<number>): Promise<Response> {
    return service({
      url: '/home/subject/deleteHomeSubject',
      method: 'post',
      data,
    })
  },

  /**
   * @description 获取课程时间表列表
   * @author Jinx
   * @date 2021-08-24 13:51:13
   * @returns {*}  {Promise<Response>}
   */
  homeSubjectGetHomeSubjectListSelect(): Promise<Response> {
    return service({
      url: '/home/subject/getHomeSubjectListSelect',
      method: 'get'
    })
  },

  /**
   * @description 获取空间介绍
   * @author Jinx
   * @date 2021-08-20 09:50:15
   * @returns {*}  {Promise<Response>}
   */
  homeSpaceGetSpaceIntroduce(): Promise<Response> {
    return service({
      url: '/home/space/getSpaceIntroduce',
      method: 'get',
    })
  },

  /**
   * @description 编辑空间介绍
   * @author Jinx
   * @date 2021-08-20 11:23:27
   * @param {SysPropertiesItemList} data
   * @returns {*}  {Promise<Response>}
   */
  homeSpaceEditSpaceIntroduce(data: SysPropertiesItemList): Promise<Response> {
    return service({
      url: '/home/space/editSpaceIntroduce',
      method: 'post',
      data
    })
  },

  /**
   * @description 获取团队成员列表
   * @author Jinx
   * @date 2021-08-20 11:26:06
   * @param {PaginationRow} params
   * @returns {*}  {Promise<Response>}
   */
  homeTeamGetTeamList(params: PaginationRow): Promise<Response> {
    return service({
      url: '/home/team/getTeamList',
      method: 'get',
      params
    })
  },

  /**
   * @description 添加团队成员
   * @author Jinx
   * @date 2021-08-20 13:57:04
   * @param {SysTeam} data
   * @returns {*}  {Promise<Response>}
   */
  homeTeamAddTeam(data: SysTeam): Promise<Response> {
    return service({
      url: '/home/team/addTeam',
      method: 'post',
      data
    })
  },

  /**
   * @description 编辑团队成员
   * @author Jinx
   * @date 2021-08-20 13:57:06
   * @param {SysTeam} data
   * @returns {*}  {Promise<Response>}
   */
  homeTeamEditTeam(data: SysTeam): Promise<Response> {
    return service({
      url: '/home/team/editTeam',
      method: 'post',
      data
    })
  },

  /**
   * @description 删除团队成员
   * @author Jinx
   * @date 2021-08-20 13:57:08
   * @param {Array<number>} data
   * @returns {*}  {Promise<Response>}
   */
  homeTeamDeleteTeam(data: Array<number>): Promise<Response> {
    return service({
      url: '/home/team/deleteTeam',
      method: 'post',
      data
    })
  },

  /**
   * @description 获取合作机构列表
   * @author Jinx
   * @date 2021-08-20 14:10:31
   * @param {PaginationRow} params
   * @returns {*}  {Promise<Response>}
   */
  homeInstitutionGetInstitutionList(params: PaginationRow): Promise<Response> {
    return service({
      url: '/home/institution/getInstitutionList',
      method: 'get',
      params
    })
  },

  /**
   * @description 添加合作机构
   * @author Jinx
   * @date 2021-08-20 14:11:26
   * @param {SysInstitution} data
   * @returns {*}  {Promise<Response>}
   */
  homeInstitutionAddInstitution(data: SysInstitution): Promise<Response> {
    return service({
      url: '/home/institution/addInstitution',
      method: 'post',
      data
    })
  },

  /**
   * @description 编辑合作机构
   * @author Jinx
   * @date 2021-08-20 14:12:43
   * @param {SysInstitution} data
   * @returns {*}  {Promise<Response>}
   */
  homeInstitutionEditInstitution(data: SysInstitution): Promise<Response> {
    return service({
      url: '/home/institution/editInstitution',
      method: 'post',
      data
    })
  },

  /**
   * @description 删除合作机构
   * @author Jinx
   * @date 2021-08-20 14:12:44
   * @param {Array<number>} data
   * @returns {*}  {Promise<Response>}
   */
  homeInstitutionDeleteInstitution(data: Array<number>): Promise<Response> {
    return service({
      url: '/home/institution/deleteInstitution',
      method: 'post',
      data
    })
  },

  /**
   * @description 获取友好连接
   * @author Jinx
   * @date 2021-08-20 14:42:43
   * @returns {*}  {Promise<Response>}
   */
  homeLinkGetCooperationList(): Promise<Response> {
    return service({
      url: '/home/link/getCooperationList',
      method: 'get'
    })
  },

  /**
   * @description 添加友好连接
   * @author Jinx
   * @date 2021-08-20 14:47:59
   * @param {SysCooperation} data
   * @returns {*}  {Promise<Response>}
   */
  homeLinkAddCooperation(data: SysCooperation): Promise<Response> {
    return service({
      url: '/home/link/addCooperation',
      method: 'post',
      data
    })
  },

  /**
   * @description 编辑友好连接
   * @author Jinx
   * @date 2021-08-20 14:48:00
   * @param {SysCooperation} data
   * @returns {*}  {Promise<Response>}
   */
  homeLinkEditCooperation(data: SysCooperation): Promise<Response> {
    return service({
      url: '/home/link/editCooperation',
      method: 'post',
      data
    })
  },

  /**
   * @description 删除友好连接
   * @author Jinx
   * @date 2021-08-20 14:44:42
   * @param {Array<number>} data
   * @returns {*}  {Promise<Response>}
   */
  homeLinkDeleteCooperation(data: Array<number>): Promise<Response> {
    return service({
      url: '/home/link/deleteCooperation',
      method: 'post',
      data
    })
  }

}

export default api
