import { Response } from "@/models/request"
import service from "@/api/request"
import { PaginationRow } from "@/models/type"
import { ProductParam } from "@/models/product"

// 商品管理
const api = {
  /**
   * @description 查询商品
   * @author Jinx
   * @date 2021-10-19 19:18:32
   * @param {ProductListParams} params
   * @returns {*}  {Promise<Response>}
   */
  productList(params: ProductListParams): Promise<Response> {
    return service({
      url: '/product/list',
      method: 'get',
      params
    })
  },
  /**
   * @description 批量上下架
   * @author Jinx
   * @date 2021-10-19 19:38:52
   * @param {{ids: number, publishStatus: number}} params
   * @returns {*}  {Promise<Response>}
   */
  productUpdatePublishStatus(params: {ids: number, publishStatus: number}): Promise<Response> {
    return service({
      url: '/product/update/publishStatus',
      method: 'post',
      params
    })
  },
  /**
   * @description 批量修改删除状态
   * @author Jinx
   * @date 2021-10-19 20:08:38
   * @param {Array<number>} data
   * @returns {*}  {Promise<Response>}
   */
  productUpdateDeleteStatus(data: Array<number>): Promise<Response> {
    return service({
      url: '/product/update/deleteStatus',
      method: 'post',
      data
    })
  },
  /**
   * @description 创建商品
   * @author Jinx
   * @date 2021-10-20 09:49:25
   * @param {ProductParam} data
   * @returns {*}  {Promise<Response>}
   */
  productCreate(data: ProductParam): Promise<Response> {
    return service({
      url: '/product/create',
      method: 'post',
      data
    })
  },
  /**
   * @description 根据商品id获取商品编辑信息
   * @author Jinx
   * @date 2021-10-20 09:53:11
   * @param {number} id
   * @returns {*}  {Promise<Response>}
   */
  productUpdateInfo(id: number): Promise<Response> {
    return service({
      url: `/product/updateInfo/${id}`,
      method: 'get'
    })
  },
  productUpdate(data: ProductParam): Promise<Response> {
    return service({
      url: `/product/update/${data.id}`,
      method: 'post',
      data
    })
  }
}

export default api

export interface ProductListParams extends PaginationRow {
  brandId?: number
  keyword?: string
  productCategoryId?: number
  productSn?: string
  publishStatus?: number
  verifyStatus?: number
}
