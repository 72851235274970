import { defineComponent } from "vue";

import './index.scss'

/** 自定义分页
 *  @type {*} */
const Pagination = defineComponent({

  props: {
    onSizeChange: Function,
    onCurrentChange: Function,
    total: {
      required: true,
      type: Number
    }
  },

  emits: ['sizeChange', 'currentChange', 'paginationObj'],

  data(){
    return {
      paginationObj: Object.assign({}, this.$store.getters.GetPaginationObj)
    }
  },
  methods: {
    /**
     * @description 个数
     * @author Jinx
     * @date 2021-08-13 16:34:27
     * @param {*} pageSize
     */
    handleSizeChange(pageSize){
      this.paginationObj.pageSize = pageSize
      this.$emit('sizeChange', pageSize)
    },
    /**
     * @description 页数
     * @author Jinx
     * @date 2021-08-13 16:34:30
     * @param {*} pageNum
     */
    handleCurrentChange(pageNum){
      this.paginationObj.pageNum = pageNum
      this.$emit('currentChange', pageNum)
    }
  },
  render() {
    return (
      <el-pagination
        onSizeChange={ this.handleSizeChange }
        onCurrentChange={ this.handleCurrentChange }
        currentPage={ this.paginationObj.pageNum }
        pageSizes={ this.paginationObj.pageSizes }
        pageSize={ this.paginationObj.pageSize }
        layout="total, sizes, prev, pager, next, jumper"
        total={ this.total }
      >
      </el-pagination>
    )
  }
})

export default Pagination
