import { Response } from "@/models/request"
import service from "@/api/request"
import { Role, RoleListParams } from "@/models/role"

const api = {
  /**
   * @description 根据角色名称分页获取角色列表
   * @author Jinx
   * @date 2021-08-13 15:25:41
   * @param {RoleListParams} params
   * @returns {*}  {Promise<Response>}
   */
  roleList(params: RoleListParams): Promise<Response> {
    return service({
      url: '/role/list',
      method: 'get',
      params
    })
  },

  /**
   * @description 批量删除角色
   * @author Jinx
   * @date 2021-08-16 15:37:46
   * @param {Array<number>} data
   * @returns {*}  {Promise<Response>}
   */
  roleDelete(data: Array<number>): Promise<Response> {
    return service({
      url: '/role/delete',
      method: 'post',
      data
    })
  },

  /**
   * @description 添加角色
   * @author Jinx
   * @date 2021-08-16 15:42:21
   * @param {Role} data
   * @returns {*}  {Promise<Response>}
   */
  roleCreate(data: Role): Promise<Response> {
    return service({
      url: '/role/create',
      method: 'post',
      data
    })
  },

  /**
   * @description 修改角色
   * @author Jinx
   * @date 2021-08-17 14:18:59
   * @param {number} id
   * @param {Role} data
   * @returns {*}  {Promise<Response>}
   */
  roleUpdate(id: number, data: Role): Promise<Response> {
    return service({
      url: `/role/update/${id}`,
      method: 'post',
      data
    })
  },

  /**
   * @description 获取所有角色
   * @author Jinx
   * @date 2021-08-17 10:10:35
   * @returns {*}  {Promise<Response>}
   */
  roleListAll(): Promise<Response> {
    return service({
      url: '/role/listAll',
      method: 'get'
    })
  },

  /**
   * @description 获取角色相关菜单
   * @author Jinx
   * @date 2021-08-18 16:34:17
   * @param {number} roleId
   * @returns {*}  {Promise<Response>}
   */
  roleListMenu(roleId: number): Promise<Response> {
    return service({
      url: `/role/listMenu/${roleId}`,
      method: 'get'
    })
  },

  /**
   * @description 获取角色相关资源
   * @author Jinx
   * @date 2021-08-18 16:34:58
   * @param {number} roleId
   * @returns {*}  {Promise<Response>}
   */
  roleListResource(roleId: number): Promise<Response> {
    return service({
      url: `/role/listResource/${roleId}`,
      method: 'get'
    })
  },

  /**
   * @description 给角色分配菜单
   * @author Jinx
   * @date 2021-08-18 17:37:30
   * @param {Array<number>} data
   * @param {number} roleId
   * @returns {*}  {Promise<Response>}
   */
  roleAllocMenu(data: Array<number>, roleId: number): Promise<Response> {
    return service({
      url: `/role/allocMenu`,
      method: 'post',
      params: { roleId },
      data
    })
  },

  /**
   * @description 给角色分配资源
   * @author Jinx
   * @date 2021-08-18 17:38:27
   * @param {Array<number>} data
   * @param {number} roleId
   * @returns {*}  {Promise<Response>}
   */
  roleAllocResource(data: Array<number>, roleId: number): Promise<Response> {
    return service({
      url: `/role/allocResource`,
      method: 'post',
      params: { roleId },
      data
    })
  }

}

export default api
