import { Response } from "@/models/request"
import service, { exportFile } from "@/api/request"
import { SmsActivity, SmsActivityArticle, SmsActivityParams, SmsShow, SmsShowParams } from "@/models/activity"
import { CaphterParams } from "@/models/subject"

/* 活动管理 */
const api = {
  /**
   * @description 获取活动列表
   * @author Jinx
   * @date 2021-08-24 16:56:31
   * @param {SmsActivityParams} params
   * @returns {*}  {Promise<Response>}
   */
  activeActivityGetActivityList(params: SmsActivityParams): Promise<Response> {
    return service({
      url: '/active/activity/getActivityList',
      method: 'get',
      params
    })
  },

  /**
   * @description 添加活动
   * @author Jinx
   * @date 2021-08-24 18:22:51
   * @param {SmsActivity} data
   * @returns {*}  {Promise<Response>}
   */
  activeActivityAddActivity(data: SmsActivity): Promise<Response> {
    return service({
      url: '/active/activity/addActivity',
      method: 'post',
      data
    })
  },

  /**
   * @description 修改活动
   * @author Jinx
   * @date 2021-08-24 18:22:53
   * @param {SmsActivity} data
   * @returns {*}  {Promise<Response>}
   */
  activeActivityEditActivity(data: SmsActivity): Promise<Response> {
    return service({
      url: '/active/activity/editActivity',
      method: 'post',
      data
    })
  },

  /**
   * @description 删除活动
   * @author Jinx
   * @date 2021-08-24 18:27:03
   * @param {Array<number>} data
   * @returns {*}  {Promise<Response>}
   */
  activeActivityDeleteActivity(data: Array<number>): Promise<Response> {
    return service({
      url: '/active/activity/deleteActivity',
      method: 'post',
      data
    })
  },

  /**
   * @description 获取展览列表
   * @author Jinx
   * @date 2021-08-25 13:52:13
   * @param {SmsShowParams} params
   * @returns {*}  {Promise<Response>}
   */
  activeShowGetShowList(params: SmsShowParams): Promise<Response> {
    return service({
      url: '/active/show/getShowList',
      method: 'get',
      params
    })
  },

  /**
   * @description 添加展览
   * @author Jinx
   * @date 2021-08-25 13:53:31
   * @param {SmsShow} data
   * @returns {*}  {Promise<Response>}
   */
  activeShowAddShow(data: SmsShow): Promise<Response> {
    return service({
      url: '/active/show/addShow',
      method: 'post',
      data
    })
  },

  /**
   * @description 编辑展览
   * @author Jinx
   * @date 2021-08-25 13:53:32
   * @param {SmsShow} data
   * @returns {*}  {Promise<Response>}
   */
  activeShowEditShow(data: SmsShow): Promise<Response> {
    return service({
      url: '/active/show/editShow',
      method: 'post',
      data
    })
  },

  /**
   * @description 删除展览
   * @author Jinx
   * @date 2021-08-25 13:53:34
   * @param {SmsShow} data
   * @returns {*}  {Promise<Response>}
   */
  activeShowDeleteShow(data: Array<number>): Promise<Response> {
    return service({
      url: '/active/show/deleteShow',
      method: 'post',
      data
    })
  },

  /**
   * @description 获取艺术家列表
   * @author Jinx
   * @date 2021-08-26 15:31:10
   * @param {SmsShowParams} params
   * @returns {*}  {Promise<Response>}
   */
  activeArticleGetArticleList(params: SmsShowParams): Promise<Response> {
    return service({
      url: '/active/article/getArticleList',
      method: 'get',
      params
    })
  },

  /**
   * @description 添加艺术家
   * @author Jinx
   * @date 2021-08-26 15:37:01
   * @param {SmsActivityArticle} data
   * @returns {*}  {Promise<Response>}
   */
  activeArticleAddArticle(data: SmsActivityArticle): Promise<Response> {
    return service({
      url: '/active/article/addArticle',
      method: 'post',
      data
    })
  },

  /**
   * @description 编辑艺术家
   * @author Jinx
   * @date 2021-08-26 15:37:07
   * @param {SmsActivityArticle} data
   * @returns {*}  {Promise<Response>}
   */
  activeArticleEditArticle(data: SmsActivityArticle): Promise<Response> {
    return service({
      url: '/active/article/editArticle',
      method: 'post',
      data
    })
  },

  /**
   * @description 删除艺术家
   * @author Jinx
   * @date 2021-08-26 15:37:16
   * @param {Array<number>} data
   * @returns {*}  {Promise<Response>}
   */
  activeArticleDeleteArticle(data: Array<number>): Promise<Response> {
    return service({
      url: '/active/article/deleteArticle',
      method: 'post',
      data
    })
  },
  /**
   * @description 获取艺术家列表(选择框)
   * @author Jinx
   * @date 2021-08-26 15:56:33
   * @returns {*}  {Promise<Response>}
   */
  activeArticleGetArticleListSelect(): Promise<Response> {
    return service({
      url: '/active/article/getArticleListSelect',
      method: 'get'
    })
  },
  /**
   * @description 批量添加返款金额
   * @author Jinx
   * @date 2021-11-02 18:13:37
   * @param {{returnPrice: string}} params
   * @param {Array<number>} data
   * @returns {*}  {Promise<Response>}
   */
  activeSignUpActivityAddSignUpActivityReturnPrice(params: {returnPrice: string}, data: Array<number>): Promise<Response> {
    return service({
      url: '/active/signUpActivity/addSignUpActivityReturnPrice',
      method: 'post',
      params,
      data
    })
  },

  /**
   * @description 根据活动id获取报名人数信息
   * @author Jinx
   * @date 2021-08-27 14:01:16
   * @param {CaphterParams} params
   * @returns {*}  {Promise<Response>}
   */
  activeSignUpActivityGetSignUpActivityMemberById(params: CaphterParams): Promise<Response> {
    return service({
      url: `/active/signUpActivity/getSignUpActivityMemberById/${params.id}`,
      method: 'get',
      params
    })
  },

  /**
   * @description 导出活动报名表格
   * @author Jinx
   * @date 2021-08-27 14:20:41
   * @param {number} id
   * @param {{ fileType: string, [prop:string]: any }} data
   * @returns {*}  {Promise<any>}
   */
  activeSignUpActivityExportSignUpActivity(id: number, data: { fileType: string, [prop:string]: any }): Promise<any> {
    return exportFile({
      url: `/active/signUpActivity/exportSignUpActivity/${id}`,
      method: 'post',
      isDown: true,
      ...data
    })
  }

}

export default api
