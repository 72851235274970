import { Response } from "@/models/request"
import service from "@/api/request"
import { SysMonth, SysSelectTypeOption } from "@/models/select"

// 字典表
const api = {
  addSysSelectType (data: unknown): Promise<Response> {
    return service({
      url: '/select/addSysSelectType',
      method: 'post',
      data
    })
  },

  /**
   * @description 根据选项框id获取详细内容
   * @author Jinx
   * @date 2021-08-19 17:06:10
   * @param {number} id
   * @returns {*}  {Promise<Response>}
   */
  selectGetSysSelectTypeOption (id: number): Promise<Response> {
    return service({
      url: `/select/getSysSelectTypeOption/${id}`,
      method: 'get'
    })
  },

  /**
   * @description 根据类别添加选项框详情
   * @author Jinx
   * @date 2021-08-19 17:08:55
   * @param {SysSelectTypeOption} data
   * @returns {*}  {Promise<Response>}
   */
  selectAddSysSelectTypeOption (data: SysSelectTypeOption): Promise<Response> {
    return service({
      url: `/select/addSysSelectTypeOption`,
      method: 'post',
      data
    })
  },

  /**
   * @description 删除详细选项
   * @author Jinx
   * @date 2021-08-19 17:49:03
   * @param {number} id
   * @returns {*}  {Promise<Response>}
   */
  selectDeleteSysSelectTypeOption (data: Array<number>): Promise<Response> {
    return service({
      url: `/select/deleteSysSelectTypeOption`,
      method: 'delete',
      data
    })
  },

  /**
   * @description 修改某选项类别内容信息
   * @author Jinx
   * @date 2021-08-19 18:11:54
   * @param {SysSelectTypeOption} data
   * @returns {*}  {Promise<Response>}
   */
  selectModifySysSelectTypeOption (data: SysSelectTypeOption): Promise<Response> {
    return service({
      url: `/select/modifySysSelectTypeOption`,
      method: 'post',
      data
    })
  },

  /**
   * @description 获取月份
   * @author Jinx
   * @date 2021-08-25 15:28:23
   * @param {{year: string}} params
   * @returns {*}  {Promise<Response>}
   */
  selectGetMonth (params: {year: string}): Promise<Response> {
    return service({
      url: `/select/getMonth`,
      method: 'get',
      params
    })
  },

  /**
   * @description 新增月份
   * @author Jinx
   * @date 2021-08-25 15:29:52
   * @param {SysMonth} data
   * @returns {*}  {Promise<Response>}
   */
  selectAddMonth (data: SysMonth): Promise<Response> {
    return service({
      url: `/select/addMonth`,
      method: 'post',
      data
    })
  },

  /**
   * @description 编辑月份
   * @author Jinx
   * @date 2021-08-25 15:29:45
   * @param {SysMonth} data
   * @returns {*}  {Promise<Response>}
   */
  selectEditMonth (data: SysMonth): Promise<Response> {
    return service({
      url: `/select/editMonth`,
      method: 'post',
      data
    })
  },

  /**
   * @description 删除月份
   * @author Jinx
   * @date 2021-08-25 15:29:40
   * @param {Array<number>} data
   * @returns {*}  {Promise<Response>}
   */
  selectDeleteMonth (data: Array<number>): Promise<Response> {
    return service({
      url: `/select/deleteMonth`,
      method: 'post',
      data
    })
  },
  /**
   * @description 获取已添加的年份
   * @author Jinx
   * @date 2021-08-26 09:45:20
   * @returns {*}  {Promise<Response>}
   */
  selectGetYear(): Promise<Response> {
    return service({
      url: '/select/getYear',
      method: 'get'
    })
  },

  /**
   * @description 根据id获取月份
   * @author Jinx
   * @date 2021-08-26 11:28:12
   * @param {number} id
   * @returns {*}  {Promise<Response>}
   */
  selectGetMonthById(id: number): Promise<Response> {
    return service({
      url: `/select/getMonthById/${id}`,
      method: 'get'
    })
  }

}

export default api
