const TokenKey = 'TAOSSERACT_TOKEN'

/**
 * @description 获取token
 * @author Jinx
 * @date 2021-32-04 10:08:00
 * @export
 * @returns {*}  {(string | null)}
 */
export function getToken(): string | null {
  return window.localStorage.getItem(TokenKey)
}

/**
 * @description 修改token
 * @author Jinx
 * @date 2021-32-04 10:08:04
 * @export
 * @param {string} token
 * @returns {*}  {void}
 */
export function setToken(token: string): void {
  return window.localStorage.setItem(TokenKey, token)
}

/**
 * @description 删除token
 * @author Jinx
 * @date 2021-32-04 10:08:05
 * @export
 * @returns {*}  {void}
 */
export function removeToken(): void {
  return window.localStorage.removeItem(TokenKey)
}
