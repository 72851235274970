import { defineComponent, ref, toRefs } from "vue";
import './index.scss'

/**
 *  开关
 *  @type {*} */
const Switch = defineComponent({
  props: {
    switchBool: {
      required: true
    },
    bindValue: {
      type: Object,
      default: () => ({open: 1, close: 0})
    },
    onSwitch: Function
  },

  emits: ['switch'],

  setup(props, { emit }) {
    const { switchBool, bindValue } = toRefs(props)

    const isBool = ref(switchBool.value)
    const openValue = bindValue.value.open
    const closeValue = bindValue.value.close

    function switchChange() {
      // open 0 close 1
      isBool.value = isBool.value === openValue ? closeValue : openValue
      emit('switch', isBool.value)
    }

    return () => (
      <div class={['switch', isBool.value === openValue && 'switch-active']} onClick={switchChange}>
        <span class="switch-block" />
      </div>
    )
  }
})

export default Switch
