import Good from "@/views/good";
import { RouteRecordRaw } from "vue-router";

const goodRoute: RouteRecordRaw = {
  path: '/good',
  component: Good,
  name: 'Good',
  meta: { title: '作品管理', icon: 'good', roles: ['作品管理'] }
}

export default goodRoute
