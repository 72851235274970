/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { RouteRecordRaw } from "vue-router";
import Activity from '@/views/activity/index.vue'

const Active = () => import('@/views/activity/active')
const Show = () => import('@/views/activity/show')
const Art = () => import('@/views/activity/art')

const activityRoute: RouteRecordRaw = {
  path: '/activity',
  component: Activity,
  name: 'Activity',
  redirect: '/activity/active',
  meta: { title: '活动管理', icon: 'activity', roles: ['活动管理'] },
  children: [
    {
      path: 'active',
      component: Active,
      name: 'Active',
      meta: { title: '活动管理', roles: ['活动管理'] },
    },
    {
      path: 'show',
      component: Show,
      name: 'Show',
      meta: { title: '展览管理', roles: ['活动管理'] },
    },
    {
      path: 'art',
      component: Art,
      name: 'Art',
      meta: { title: '艺术家管理', roles: ['活动管理'] }
    }
  ]
}

export default activityRoute
