import { defineComponent, toRefs } from "vue";
import './index.scss'

/** 卡片
 *  @type {*}
 *  */
const Card = defineComponent({
  props: {
    title: {
      type: String,
      default: '标题'
    },
    back: {
      type: Boolean,
      default: false
    },
    onGoBack: Function
  },
  emits: ['goBack'],
  setup(props, { slots, emit }){

    const { title, back } = toRefs(props)

    function goBack(): void {
      emit('goBack')
    }

    return (): JSX.Element => (
      <div class="card">
        <div class="card-header">
          <div class="card-header-left">
            { slots.title ? slots.title() : CardTitle(title.value) }
            { back.value && <div class="card-back" onClick={ goBack } >
              <i class="el-icon-arrow-left"/>
              返回
            </div> }
          </div>
          <div class="card-suffix">
            { slots.suffix && slots.suffix()}
          </div>
        </div>
        { slots.default?.() }
      </div>
    )
  }
})

export function CardTitle(title?: string): JSX.Element {
  return <div class="card-title" >{title}</div>
}

export default Card
