/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* 课程管理 */

import course from '@/views/course/index.vue'

import { RouteRecordRaw } from "vue-router";
const Subject = () => import('@/views/course/subject/index')
const Teacher = () => import('@/views/course/teacher/index')

const courseRoute: RouteRecordRaw = {
  path: '/course',
  component: course,
  name: 'Course',
  redirect: '/course/subject',
  meta: { title: '课程管理', icon: 'course', roles: ['课程管理'] },
  children: [
    {
      path: 'subject',
      component: Subject,
      name: 'Subject',
      meta: { title: '课程管理', roles: ['课程管理'] },
    },
    {
      path: 'teacher',
      component: Teacher,
      name: 'Teacher',
      meta: { title: '导师管理', roles: ['课程管理'] },
    }
  ]
}

export default courseRoute
