import api from "@/api";
import Button from "@/components/Button";
import Pagination from "@/components/Pagination";
import Search from "@/components/Search";
import { Role } from "@/models/role";
import { ButtonListRow, PaginationRow, SearchListRow, TableRow } from "@/models/type";
import { debounceClick, typeWatch } from "@/utils";
import { ElMessageBox, ElNotification } from "element-plus";
import { Options, Vue } from "vue-class-component";

/**
 * @description 角色管理
 * @author Jinx
 * @date 2021-08-16 13:55:01
 * @export
 * @class CompanyRole
 * @extends {Vue}
 */
@Options({
  data(){
    return {
      tableData: [],
      roleForm: {}
    }
  },
  watch: {
    type(val?: string): void {
      this.title = typeWatch(val)
    },
    listSum(val:number): void {
      if(val){
        this.getList()
      }
    }
  }
})
export default class CompanyRole extends Vue {
  private roleId = 0

  public activeAssign?: Array<any> = [] // 选中的列表

  private assignTitle = ''
  private assignBool = false
  public tableData?: Array<any>

  private total = 0
  private listSum = 0 // 搞不懂this为什么不指向proxy 先加个这玩意 通知更新吧

  private detailBool = false
  private type = ''
  private title = ''
  public ids: Array<any> = []

  public menuList: Array<any> = []
  public resourceList: Array<any> = []

  private roleForm: Role = {
    name: '',
    description: '',
    adminCount: 0,
    status: 1,
    sort: 0
  }
  private formRules = {
    name: [
      { required: true, message: '请输入角色名称' }
    ]
  }

  private searchObj?: { keyword?: string }

  public tableList: Array<TableRow> = [
    { label: '角色名称', prop: 'name' },
    { label: '角色描述', prop: 'description' },
    { label: '状态', prop: 'status', slot: {
      default: (scope: any | undefined): JSX.Element => <span style={{ color: scope.row.status ? '#67C23A' : '#E6A23C' }}>{ scope.row.status ? '已启用' : '未启用' }</span>
    } },
    { label: '操作', slot: {
      default: (scope: any | undefined): JSX.Element => <div>
        <span class="span-button-primary" onClick={() => this.roleEdit(scope.row)}>编辑</span>
        <span class="span-button-danger" onClick={() => this.roleDel(scope.row.id)}>删除</span>
        <span class="span-button-primary" onClick={() => this.menuAssign(scope.row.id)}>分配菜单</span>
        <span class="span-button-primary" onClick={() => this.resourceAssign(scope.row.id)}>分配资源</span>
      </div>
    } }
  ]

  /**
   * @description 分配菜单
   * @author Jinx
   * @date 2021-08-18 16:32:09
   * @memberof CompanyRole
   */
  menuAssign(roleId: number): void {
    api.roleListMenu(roleId).then(res => {
      this.roleId = roleId
      this.assignTitle = '分配菜单'
      this.activeAssign = res.data.map(item => item.id)
      this.assignBool = true
    })
  }

  /**
   * @description 分配资源
   * @author Jinx
   * @date 2021-08-18 16:32:20
   * @memberof CompanyRole
   */
  resourceAssign(roleId: number): void {
    this.roleId = roleId
    api.roleListResource(roleId).then(res => {
      this.assignTitle = '分配资源'
      this.activeAssign = res.data.map(item => item.id)
      this.assignBool = true
    })
  }

  /**
   * @description 点击回调方法
   * @author Jinx
   * @date 2021-08-16 17:26:47
   * @param {*} click
   * @memberof CompanyRole
   */
  buttonClick(click: string): void {
    this[click]()
  }

  /**
   * @description 添加角色
   * @author Jinx
   * @date 2021-08-13 17:10:05
   * @memberof CompanyRole
   */
  roleAdd(): void {
    this.type = 'add'
    this.detailBool = true
  }

  /**
   * @description 编辑角色
   * @author Jinx
   * @date 2021-08-17 14:14:24
   * @param {*} row
   * @memberof CompanyRole
   */
  roleEdit(row: unknown): void {
    this.roleForm = Object.assign({}, row)
    this.type = 'edit'
    this.detailBool = true
  }

  /**
   * @description 删除角色
   * @author Jinx
   * @date 2021-08-13 17:09:59
   * @memberof CompanyRole
   */
  roleDel(id?: number): void {
    const ids = id ? [id] : this.ids
    if(!ids.length) {
      ElNotification({
        type: 'warning',
        message: '请选择要删除的角色列表'
      })
      return
    }
    ElMessageBox.confirm('确定删除该角色？', '删除角色', {
      confirmButtonText: '删除角色',
      type: 'warning'
    }).then(() => {
      api.roleDelete(ids).then(() => {
        ElNotification({
          type: 'success',
          title: '成功',
          message: '角色删除成功'
        })
        id ? this.listSum++ : this.getList()
      })
    }).catch(() => {
      return false
    })
  }

  /**
   * @description 获取 菜单 资源数据
   * @author Jinx
   * @date 2021-08-18 16:42:43
   * @memberof CompanyRole
   */
  getMenuAndResourceList(): void {
    Promise.all([
      api.menuTreeList(),
      api.resourceListAll(),
      api.resourceCategoryListAll()
    ]).then(res => {
      this.menuList = res[0].data
      res[2].data.forEach(item => {
        item.children = res[1].data.filter(i => i.categoryId === item.id)
        item.id = `jinx_${item.id}`
      })

      this.resourceList = res[2].data
    })
  }

  /**
   * @description 按钮列表
   * @author Jinx
   * @date 2021-08-16 11:07:00
   * @type {Array<ButtonListRow>}
   * @memberof CompanyRole
   */
  buttonList: Array<ButtonListRow> = [
    { title: '添加', icon: 'el-icon-plus', click: 'roleAdd' },
    { title: '删除', icon: 'el-icon-minus', type: 'danger', click: 'roleDel' }
  ]

  /**
   * @description 搜索列表
   * @author Jinx
   * @date 2021-08-16 11:07:07
   * @type {Array<SearchListRow>}
   * @memberof CompanyRole
   */
  searchList: Array<SearchListRow> = [
    { label: '角色名称', type: 'input', submitKey: 'keyword' }
  ]

  /**
   * @description 获取角色列表
   * @author Jinx
   * @date 2021-08-13 16:41:29
   * @memberof CompanyRole
   */
  getList(): void {
    const $pagination = this.$refs.pagination as any
    const { pageSize, pageNum } = $pagination.paginationObj as PaginationRow

    api.roleList({ pageSize, pageNum, ...this.searchObj }).then(res => {
      this.tableData = res.data.list
      this.total = res.data.total
    })
  }


  /**
   * @description 选择项
   * @author Jinx
   * @date 2021-08-13 16:48:43
   * @param {*} row
   * @memberof CompanyRole
   */
  selectionChange(row: Array<any>): void {
    this.ids = row.map(item => item.id)
  }

  /**
   * @description 根据查询条件搜索
   * @author Jinx
   * @date 2021-08-16 11:09:56
   * @param {*} searchObj
   * @memberof CompanyRole
   */
  search(searchObj: { keyword?: string }): void {
    this.searchObj = searchObj
    this.getList()
  }

  /**
   * @description 关闭对话框
   * @author Jinx
   * @date 2021-08-16 13:53:34
   * @memberof CompanyRole
   */
  detailClose(): void {
    this.type = ''
    this.detailBool = false
    this.roleForm = {
      name: '',
      description: '',
      adminCount: 0,
      status: 1,
      sort: 0
    }
  }

  /**
   * @description 对话框加载完
   * @author Jinx
   * @date 2021-08-18 17:26:43
   * @memberof CompanyRole
   */
  opened(): void {
    const __menuListTree = this.$refs.menuListTree as any
    __menuListTree.setCheckedKeys(this.activeAssign)
  }

  assignDom(): JSX.Element {
    const slots = {
      footer: () => (
        <span class="dialog-footer">
          <button class="dialog-footer-btn dialog-footer-btn-l" onClick={ this.assignClose } >取 消</button>
          <button class="dialog-footer-btn dialog-footer-btn-r" onClick={ () => debounceClick(this.assignSubmit) }>确 定</button>
        </span>
      )
    }
    return (
      <el-dialog
        title={ this.assignTitle }
        v-model={ this.assignBool }
        width="30%"
        before-close={ this.assignClose }
        v-slots={ slots }
        onOpened={this.opened}
      >
        <el-tree
          ref="menuListTree"
          data={ this.assignTitle === '分配菜单' ? this.menuList : this.resourceList }
          props={{ children: 'children', label: this.assignTitle === '分配菜单' ? 'title' : 'name' }}
          show-checkbox
          node-key="id"
        >
        </el-tree>
      </el-dialog>
    )
  }

  /**
   * @description 关闭分配对话框
   * @author Jinx
   * @date 2021-08-18 17:05:25
   * @memberof CompanyRole
   */
  assignClose(): void {
    this.assignBool = false
    this.roleId = 0
    this.activeAssign = []
  }

  /**
   * @description 提交分配对话框内容
   * @author Jinx
   * @date 2021-08-18 17:05:23
   * @memberof CompanyRole
   */
  assignSubmit(): void {
    const __menuListTree = this.$refs.menuListTree as any
    const list = Object.assign([], __menuListTree.getCheckedKeys()).filter(item => !String(item).includes('jinx'))
    this.assignTitle === '分配菜单'
      ? (
        api.roleAllocMenu(list, this.roleId).then(() => {
          ElNotification({
            title: '成功',
            type: 'success',
            message: '分配菜单成功'
          })
          this.assignClose()
        })
      )
      : (
        api.roleAllocResource(list, this.roleId).then(() => {
          ElNotification({
            title: '成功',
            type: 'success',
            message: '分配资源成功'
          })
          this.assignClose()
        })
      )
  }


  /**
   * @description 对话框
   * @author Jinx
   * @date 2021-08-16 13:53:51
   * @returns {*}  {JSX.Element}
   * @memberof CompanyRole
   */
  roleDetailDom(): JSX.Element {
    const slots = {
      footer: () => (
        <span class="dialog-footer">
          <button class="dialog-footer-btn dialog-footer-btn-l" onClick={ this.detailClose } >取 消</button>
          <button class="dialog-footer-btn dialog-footer-btn-r" onClick={ () => debounceClick(this.submit) }>确 定</button>
        </span>
      )
    }
    return (
      <el-dialog
        title={ `${ this.title }角色` }
        v-model={ this.detailBool }
        width="30%"
        before-close={ this.detailClose }
        v-slots={ slots }
      >
        <el-form
          ref="form"
          model={ this.roleForm }
          rules={ this.formRules }
          label-width="120px"
          class="el-form-tao"
        >
          <el-form-item label="角色名称" prop="name">
            <el-input v-model={ this.roleForm.name } clearable placeholder="请输入角色名称" />
          </el-form-item>
          <el-form-item label="后台用户数量" prop="adminCount">
            <el-input-number style="width: 100%" v-model={ this.roleForm.adminCount } min={ 0 } clearable placeholder="请输入角色名称" />
          </el-form-item>
          <el-form-item label="排序" prop="sort">
            <el-input-number style="width: 100%" v-model={ this.roleForm.sort } clearable placeholder="请输入排序" />
          </el-form-item>
          <el-form-item label="描述" prop="description">
            <el-input type="textarea" style="width: 100%" v-model={ this.roleForm.description } clearable placeholder="请输入描述" />
          </el-form-item>
          <el-form-item label="状态" prop="status">
            <el-radio-group v-model={ this.roleForm.status }>
              <el-radio label={ 0 }>禁用</el-radio>
              <el-radio label={ 1 }>启用</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form>
      </el-dialog>
    )
  }

  /**
   * @description 提交表单
   * @author Jinx
   * @date 2021-08-17 09:28:26
   * @memberof CompanyRole
   */
  submit(): void {
    const __ref = this.$refs.form as any
    __ref.validate(v => {
      if(v) {
        const form = Object.assign({}, this.roleForm)
        this.type === 'add'
          ? this.submitAdd(form)
          : this.submitEdit(form)
      } else {
        return false
      }
    })
  }

  /**
   * @description 添加角色
   * @author Jinx
   * @date 2021-08-17 14:15:04
   * @memberof CompanyRole
   */
  submitAdd(form: Role): void {
    delete form.id
    api.roleCreate(form).then(() => {
      ElNotification({
        type: 'success',
        title: '成功',
        message: '新增成功'
      })
      this.detailClose()
      this.getList()
    })
  }

  /**
   * @description 删除角色
   * @author Jinx
   * @date 2021-08-17 14:15:10
   * @memberof CompanyRole
   */
  submitEdit(form: Role): void {
    api.roleUpdate(form.id as number, form).then(() => {
      ElNotification({
        type: 'success',
        title: '成功',
        message: '修改角色成功'
      })
      this.detailClose()
      this.getList()
    })
  }

  created(): void {
    this.getMenuAndResourceList()
  }

  render(): JSX.Element {
    return <div class="company-role">
      <Search list={ this.searchList } onSearch={ this.search } />
      <Button list={ this.buttonList } onClick={ this.buttonClick } />
      <el-table
        data={ this.tableData }
        onSelectionChange={ this.selectionChange }
      >
        <el-table-column type="selection" width="55" />
        {this.tableList.map(item => (
          <el-table-column
            label={ item.label }
            prop={ item.prop }
            align={ item.align || 'center' }
            v-slots={ item.slot }
          />
        ))}
      </el-table>
      <Pagination ref="pagination" total={ this.total } onCurrentChange={ this.getList } onSizeChange={ this.getList } />
      {this.detailBool && this.roleDetailDom()}
      {this.assignBool && this.assignDom()}
    </div>
  }

  mounted(): void {
    this.getList()
  }
}
