import SvgIcon from "@/components/SvgIcon";
import { defineComponent, toRefs } from "vue";

const Item = defineComponent({
  props: {
    icon: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    }
  },
  setup(props){
    const { icon, title } = toRefs(props)
    const vnodes: Array<JSX.Element> = []

    if(icon.value) {
      if (icon.value.includes('el-icon')) {
        vnodes.push(<i class={[icon.value, 'sub-el-icon']} />)
      } else {
        vnodes.push(<SvgIcon iconClass={icon.value} />)
      }
    }

    if(title.value) {
      vnodes.push(<span>{title.value}</span> )
    }

    return () => vnodes
  }
})

export default Item
