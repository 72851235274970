import { Response } from "@/models/request"
import service from "@/api/request"
import { UmsMenu } from "@/models/menu"

/* 菜单管理 */
const api = {
  /**
   * @description 树形结构返回所有菜单列表
   * @author Jinx
   * @date 2021-08-17 15:33:06
   * @returns {*}  {Promise<Response>}
   */
  menuTreeList(): Promise<Response> {
    return service({
      url: '/menu/treeList',
      method: 'get'
    })
  },

  /**
   * @description 添加后台菜单
   * @author Jinx
   * @date 2021-08-17 17:33:45
   * @param {UmsMenu} data
   * @returns {*}  {Promise<Response>}
   */
  menuCreate(data: UmsMenu): Promise<Response> {
    return service({
      url: '/menu/create',
      method: 'post',
      data
    })
  },

  /**
   * @description 修改后台菜单
   * @author Jinx
   * @date 2021-08-17 17:34:17
   * @param {UmsMenu} data
   * @returns {*}  {Promise<Response>}
   */
  menuUpdate(id: number, data: UmsMenu): Promise<Response> {
    return service({
      url: `/menu/update/${id}`,
      method: 'post',
      data
    })
  },

  /**
   * @description 根据ID删除后台菜单
   * @author Jinx
   * @date 2021-08-17 17:38:25
   * @param {number} id
   * @returns {*}  {Promise<Response>}
   */
  menuDelete(id: number): Promise<Response> {
    return service({
      url: `/menu/delete/${id}`,
      method: 'post'
    })
  },

  menuUpdateHidden(id: number, params: { hidden: number }): Promise<Response> {
    return service({
      url: `/menu/updateHidden/${id}`,
      method: 'post',
      params
    })
  }

}

export default api
