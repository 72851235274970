import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus' // element-ui
import locale from 'element-plus/lib/locale/lang/zh-cn'
import 'dayjs/locale/zh-cn'
import api from "./api"
import 'default-passive-events'

import './permission' // permission control

import '@/style/index.scss'

const app = createApp(App)

app.config.globalProperties.$api = api

app.use(store).use(router).use(ElementPlus, { locale }).mount('#app')
