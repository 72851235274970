import api from "@/api";
import Button from "@/components/Button";
import { UmsMenu } from "@/models/menu";
import { ButtonListRow, TableRow } from "@/models/type";
import { debounceClick, typeWatch } from "@/utils";
import { ElMessageBox, ElNotification } from "element-plus";
import { Options, Vue } from "vue-class-component";

import Icon from '@/icons'
import SvgIcon from "@/components/SvgIcon";
import Switch from "@/components/Switch";
import { mapGetters } from "vuex";

/**
 * @description 菜单管理
 * @author Jinx
 * @date 2021-08-17 17:42:23
 * @export
 * @class CompanyMenu
 * @extends {Vue}
 */
@Options({
  computed: {
    ...mapGetters(['GetMenu'])
  },
  data() {
    return {
      tableData: []
    }
  },
  watch: {
    type(val?: string): void {
      this.title = typeWatch(val)
    },
    deleteSum(sum: number): void {
      if(sum) {
        this.getList()
      }
    }
  }
})
export default class CompanyMenu extends Vue {

  public GetMenu?: Array<any>
  public menuList: Array<any> = []

  private deleteSum = 0

  public iconList: Array<string> = Icon.getNameList()

  private type = ''
  private title = ''
  private detailBool = false
  private tableData: any[] = []

  private menuForm: UmsMenu = {
    title: '',
    icon: '',
    name: '',
    redirectUrl: '',
    level: 0,
    parentId: 0,
    parentIds: [],
    hidden: 0,
    sort: 0,
  }

  private formRules = {
  }

  private buttonList: Array<ButtonListRow> = [
    { title: '添加', icon: 'el-icon-plus', click: 'menuAdd' }
  ]

  private tableList: Array<TableRow> = [
    { label: '菜单名称', prop: 'title', align: 'left' },
    { label: '排序', prop: 'sort' },
    { label: '菜单级数', prop: 'level' },
    { label: '前端名称', prop: 'name' },
    { label: '重定向路由', prop: 'redirectUrl' },
    { label: '图标',  slot: {
      default: (scope: any): JSX.Element => scope.row.icon && <SvgIcon iconClass={ scope.row.icon } />
    } },
    { label: '是否显示', slot: {
      default: (scope: any): JSX.Element => <Switch switchBool={ scope.row.hidden } bindValue={{ open: 0, close: 1 }} onSwitch={val => this.switchClick(val, scope.row.id)} />
    } },
    { label: '操作', slot: {
      default: (scope: any): JSX.Element => <div>
        <span class="span-button-primary" onClick={ () => this.menuEdit(scope.row) }>编辑</span>
        <span class="span-button-danger" onClick={ () => this.menuDelete(scope.row.id) }>删除</span>
      </div>
    } }
  ]

  /**
   * @description 开关点击事件
   * @author Jinx
   * @date 2021-08-18 10:39:36
   * @param {number} hidden
   * @param {number} id
   * @memberof CompanyMenu
   */
  switchClick(hidden: number, id: number): void {
    api.menuUpdateHidden(id, { hidden }).then(() => {
      ElNotification({
        title: '成功',
        message: '菜单状态修改成功',
        type: 'success'
      })
      this.getList()
    })
  }

  /**
   * @description 按钮点击事件
   * @author Jinx
   * @date 2021-08-17 15:11:16
   * @param {string} click
   * @memberof CompanyMenu
   */
  buttonClick(click: string): void {
    this[click]()
  }

  menuAdd(): void {
    this.type = 'add'
    this.detailBool = true
  }

  /**
   * @description 编辑菜单
   * @author Jinx
   * @date 2021-08-18 11:24:28
   * @param {UmsMenu} row
   * @memberof CompanyMenu
   */
  menuEdit(row: UmsMenu): void {
    this.menuForm = Object.assign({}, row)
    this.menuForm.parentIds = []
    const idObj = this.menuList.find(item => item.id === row.parentId)
    if(idObj) {
      // 该项目就两级菜单 暂时先这样写
      this.menuForm.parentIds.push(idObj.id as never)
    }
    this.type = 'edit'
    this.detailBool = true
  }

  menuDelete(id: number): void {
    ElMessageBox.confirm('确定删除该菜单', '删除菜单', {
      type: 'warning',
      confirmButtonText: '删除菜单'
    }).then(() => {
      api.menuDelete(id).then(() => {
        ElNotification({
          title: '成功',
          type: 'success',
          message: '删除菜单成功'
        })
        this.deleteSum++
      })
    }).catch(err => {
      return err
    })
  }

  /**
   * @description 获取列表
   * @author Jinx
   * @date 2021-08-18 11:23:31
   * @memberof CompanyMenu
   */
  getList(): void {
    this.tableData = [] // 防止图标样式不更新 重置一次
    api.menuTreeList().then(res => {
      const list = res.data
      this.removeChild(list)
      this.tableData = res.data
    })
  }

  /**
   * @description
   * @author Jinx
   * @date 2021-08-17 17:26:54
   * @param {any[any]} array
   * @memberof CompanyMenu
   */
  removeChild(array: any[any]): void {
    array.forEach(item => {
      if(item.children.length > 0) {
        this.removeChild(item.children)
      } else {
        delete item.children
      }
    })
  }

  /**
   * @description 图片部分
   * @author Jinx
   * @date 2021-08-17 18:16:12
   * @param {*} icon
   * @returns {*}  {JSX.Element}
   * @memberof CompanyMenu
   */
  iconDom(icon: string): JSX.Element {
    return <>
      {this.iconList.map(item => (
        <div
          class={['menu-icon pointer', icon && icon === item && 'menu-icon-active' ]}
          onClick={() => this.menuForm.icon = item}
        >
          <SvgIcon iconClass={ item } class="menu-icon-content" />
        </div>
      ))}
    </>
  }

  /**
   * @description 关闭对话框
   * @author Jinx
   * @date 2021-08-17 16:45:36
   * @memberof CompanyMenu
   */
  detailClose(): void {
    this.type = ''
    this.detailBool = false
    this.menuForm = {
      title: '',
      icon: '',
      name: '',
      redirectUrl: '',
      level: 0,
      parentId: 0,
      parentIds: [],
      hidden: 0,
      sort: 0,
    }
  }

  /**
   * @description 菜单详情
   * @author Jinx
   * @date 2021-08-17 16:45:03
   * @returns {*}  {JSX.Element}
   * @memberof CompanyMenu
   */
  menuDetailDom(): JSX.Element {
    const slots: any = {
      footer: (): JSX.Element => (
        <span class="dialog-footer">
          <button class="dialog-footer-btn dialog-footer-btn-l" onClick={ this.detailClose } >取 消</button>
          <button class="dialog-footer-btn dialog-footer-btn-r" onClick={ () => debounceClick(this.submit) }>确 定</button>
        </span>
      )
    }

    return (
      <el-dialog
        title={ `${ this.title }菜单` }
        v-model={ this.detailBool }
        width="30%"
        before-close={ this.detailClose }
        v-slots={ slots }
      >
        <el-form
          ref="form"
          model={ this.menuForm }
          rules={ this.formRules }
          label-width="120px"
          class="el-form-tao"
        >
          <el-form-item label="菜单名称" prop="title">
            <el-input v-model={ this.menuForm.title } clearable placeholder="请输入菜单名称" />
          </el-form-item>
          <el-form-item label="上级菜单" prop="parentIds">
            <el-cascader
              v-model={ this.menuForm.parentIds }
              options={ this.tableData }
              props={{ checkStrictly: true, label: 'title', value: 'id' }}
              clearable
              style="width: 100%"
              placeholder="请选择，不选择则默认为第一级菜单"
            />
          </el-form-item>
          <el-form-item label="排序" prop="sort">
            <el-input-number v-model={ this.menuForm.sort } clearable placeholder="请输入排序" style="width: 100%" />
          </el-form-item>
          <el-form-item label="前端名称" prop="name">
            <el-input v-model={ this.menuForm.name } clearable placeholder="请输入前端名称" />
          </el-form-item>
          <el-form-item label="重定向路由" prop="redirectUrl">
            <el-input v-model={ this.menuForm.redirectUrl } clearable placeholder="请输入重定向路由" />
          </el-form-item>
          <el-form-item label="是否显示" prop="hidden">
            <el-radio-group v-model={ this.menuForm.hidden }>
              <el-radio label={ 0 }>是</el-radio>
              <el-radio label={ 1 }>否</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="图标" prop="icon">
            {this.iconDom(this.menuForm.icon as string)}
          </el-form-item>
        </el-form>
      </el-dialog>
    )
  }

  /**
   * @description 提交表单
   * @author Jinx
   * @date 2021-08-17 16:48:40
   * @memberof CompanyMenu
   */
  submit(): void {
    const __form = this.$refs.form as any
    __form.validate(v => {
      if(v) {
        const form = Object.assign({}, this.menuForm)
        const parentIds = form.parentIds as Array<any>
        form.parentId = parentIds.length > 0 ? parentIds[parentIds.length - 1] : 0
        delete form.parentIds
        this.type === 'add'
          ? this.submitAdd(form)
          : this.submitEdit(form)
      } else {
        return false
      }
    })
  }

  /**
   * @description 新增菜单
   * @author Jinx
   * @date 2021-08-17 16:50:53
   * @param {UmsMenu} form
   * @memberof CompanyMenu
   */
  submitAdd(form: UmsMenu): void {
    delete form.id
    api.menuCreate(form).then(() => {
      ElNotification({
        title: '成功',
        type: 'success',
        message: '新增菜单成功'
      })
      this.detailClose()
      this.getList()
    })
  }

  /**
   * @description 编辑菜单
   * @author Jinx
   * @date 2021-08-17 16:51:06
   * @param {UmsMenu} form
   * @memberof CompanyMenu
   */
  submitEdit(form: UmsMenu): void {
    api.menuUpdate(form.id as number , form).then(() => {
      ElNotification({
        title: '成功',
        type: 'success',
        message: '编辑菜单成功'
      })
      this.detailClose()
      this.getList()
    })
  }

  created(): void {
    this.menuList = Object.assign([], this.GetMenu)
  }

  render(): JSX.Element {
    return (
      <div class="company-menu">
        <Button list={ this.buttonList } onClick={ this.buttonClick }/>
        <el-table
          ref="table"
          row-key="id"
          data={ this.tableData }
        >
          {this.tableList.map(item => (
            <el-table-column
              header-align="center"
              label={ item.label }
              prop={ item.prop }
              align={ item.align || 'center' }
              v-slots={ item.slot }
            />
          ))}
        </el-table>
        {this.detailBool && this.menuDetailDom()}
      </div>
    )
  }

  mounted(): void {
    this.getList()
  }
}
