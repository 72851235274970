import { SearchListRow } from "@/models/type";
import { debounceClick } from "@/utils";
import { defineComponent, toRefs, ref } from "vue";
import SvgIcon from "../SvgIcon";
import './index.scss'

/**
 *  功能搜索
 *  @type {*} */
const Search = defineComponent({
  props: {
    list: {
      type: Array,
      required: true
    },
    onSearch: Function
  },
  emits: ['search'],
  setup(props, { emit }){
    const { list } = toRefs(props)
    const selectValue = ref(0)

    /**
     * @description 根据传入列表形成搜索条件
     * @author Jinx
     * @date 2021-08-16 10:36:14
     * @returns {*}  {JSX.Element}
     */
    function getDom(): JSX.Element {
      return <>{
        list.value.map((item: any) => {
          switch(item.type) {
            case 'input':
              // 输入框
              return <div class="search-cell">
                { item.icon && <SvgIcon iconClass={ item.icon } /> }
                <span class="search-cell__label">{ item.label }</span>
                <el-input v-model={ item.value } placeholder={ item.placeholder || '请输入' } clearable style="width: 100%" />
              </div>
            case 'select':
              // TODO 下拉菜单 后期改成远程搜索
              return <div class="search-cell">
                { item.icon && <SvgIcon iconClass={ item.icon } /> }
                <span class="search-cell__label">{ item.label }</span>
                <el-select v-model={ item.value } placeholder={ item.placeholder || '请选择' } clearable style="width: 100%" filterable>
                  {item?.options.map((i:any) => (
                    <el-option
                      key={ i.value }
                      label={ i.name }
                      value={ i.value }
                    />
                  ))}
                </el-select>
              </div>
            case 'time':
              // 日期时间选择
              return <div class="search-cell">
                { item.icon && <SvgIcon iconClass={ item.icon } /> }
                <span class="search-cell__label">{ item.label }</span>
                <el-date-picker
                  v-model={ item.value }
                  type="datetimerange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  clearable
                  style="width: 100%"
                >
                </el-date-picker>
              </div>
            case 'year':
              // 日期时间选择
              return <div class="search-cell">
                { item.icon && <SvgIcon iconClass={ item.icon } /> }
                <span class="search-cell__label">{ item.label }</span>
                <el-date-picker
                  v-model={ item.value }
                  type="year"
                  placeholder={ item.placeholder || '选择' }
                  clearable
                  value-format="YYYY"
                  style="width: 100%"
                >
                </el-date-picker>
              </div>
            case 'autocomplete':
              // 远程搜索
              return <div class="search-cell">
                { item.icon && <SvgIcon iconClass={ item.icon } /> }
                <span class="search-cell__label">{ item.label }</span>
                <el-autocomplete
                  clearable
                  v-model={ item.value }
                  fetch-suggestions={item.querySearchAsync }
                  placeholder={item.placeholder || '请输入'}
                  onSelect={ select }
                />
              </div>
            default:
          }
        })
      }</>
    }

    function select(item: any): void {
      selectValue.value = item.id
    }

    /**
     * @description 点击搜索后的操作
     * @author Jinx
     * @date 2021-08-16 10:41:07
     */
    function search (): void {
      const newList = Object.assign([], list.value) // 拿到已选搜索的key value

      const searchObj: any = {}
      newList.forEach((item: SearchListRow) => {
        if( item.type === 'time' ) {
          // 如果是日期选择
          if ( item.value && item.value.length > 0 ) {
            searchObj[item.submitKey[0]] = item.value[0]
            searchObj[item.submitKey[1]] = item.value[1]
          }
        } else if( item.type === 'autocomplete') {
          searchObj[item.submitKey as string] = selectValue.value
        } else {
          if(item.value || item.value === 0) {
            searchObj[item.submitKey as string] = item.value
          }
        }
      })

      emit('search', searchObj)
    }

    return () => (
      <div class="search">
        {getDom()}
        <button class="search-button pointer" onClick={() => debounceClick(search)}>查询</button>
      </div>
    )
  }
})

export default Search
