import { Options, Vue } from "vue-class-component"
import { RouterView } from "vue-router"


@Options({
  computed: {
    key() {
      return this.$route.path
    }
  }
})
export default class AppMain extends Vue {
  key?: string
  render(): JSX.Element {
    return (
      <section class="app-main">
        <RouterView key={this.key} />
      </section>
    )
  }
}
