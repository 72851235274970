import { Response } from "@/models/request"
import service, { exportFile } from "@/api/request"
import { CaphterParams, SmsSubjectAndChapterDto, SmsSubjectTutor, SubjectParams, TeacherParams } from "@/models/subject"
import { smsHomeImgStatus } from "@/models/home"

const api = {
  /**
   * @description 获取课程列表
   * @author Jinx
   * @date 2021-08-20 17:21:43
   * @param {SubjectParams} params
   * @returns {*}  {Promise<Response>}
   */
  subjectSubjectGetSubjectList(params: SubjectParams): Promise<Response> {
    return service({
      url: '/subject/subject/getSubjectList',
      method: 'get',
      params
    })
  },

  /**
   * @description 添加课程
   * @author Jinx
   * @date 2021-08-24 13:43:13
   * @param {SmsSubjectAndChapterDto} data
   * @returns {*}  {Promise<Response>}
   */
  subjectSubjectAddSubject(data: SmsSubjectAndChapterDto): Promise<Response> {
    return service({
      url: '/subject/subject/addSubject',
      method: 'post',
      data
    })
  },

  /**
   * @description 获取导师列表
   * @author Jinx
   * @date 2021-08-20 18:03:15
   * @param {TeacherParams} params
   * @returns {*}  {Promise<Response>}
   */
  subjectTutorGetTutorList(params: TeacherParams): Promise<Response> {
    return service({
      url: '/subject/tutor/getTutorList',
      method: 'get',
      params
    })
  },

  /**
   * @description 根据课程id获取课程
   * @author Jinx
   * @date 2021-08-24 14:44:03
   * @param {number} id
   * @returns {*}  {Promise<Response>}
   */
  subjectSubjectGetSubjectById(id: number): Promise<Response> {
    return service({
      url: `/subject/subject/getSubjectById/${id}`,
      method: 'get'
    })
  },

  /**
   * @description 删除课程
   * @author Jinx
   * @date 2021-08-24 15:25:43
   * @param {Array<number>} data
   * @returns {*}  {Promise<Response>}
   */
  subjectSubjectDeleteSubject(data: Array<number>): Promise<Response> {
    return service({
      url: '/subject/subject/deleteSubject',
      method: 'post',
      data
    })
  },

  /**
   * @description 添加导师
   * @author Jinx
   * @date 2021-08-20 18:07:20
   * @param {SmsSubjectTutor} data
   * @returns {*}  {Promise<Response>}
   */
  subjectTutorAddTutor(data: SmsSubjectTutor): Promise<Response> {
    return service({
      url: '/subject/tutor/addTutor',
      method: 'post',
      data
    })
  },

  /**
   * @description 删除导师
   * @author Jinx
   * @date 2021-08-20 18:07:14
   * @param {SmsSubjectTutor} data
   * @returns {*}  {Promise<Response>}
   */
  subjectTutorEditTutor(data: SmsSubjectTutor): Promise<Response> {
    return service({
      url: '/subject/tutor/editTutor',
      method: 'post',
      data
    })
  },

  /**
   * @description 删除导师
   * @author Jinx
   * @date 2021-08-20 18:07:09
   * @param {Array<number>} data
   * @returns {*}  {Promise<Response>}
   */
  subjectTutorDeleteTutor(data: Array<number>): Promise<Response> {
    return service({
      url: '/subject/tutor/deleteTutor',
      method: 'post',
      data
    })
  },

  /**
   * @description 修改导师状态
   * @author Jinx
   * @date 2021-08-20 18:07:03
   * @param {number} id
   * @param {smsHomeImgStatus} status
   * @returns {*}  {Promise<Response>}
   */
  subjectTutorEditTutorStatus(id: number, status: smsHomeImgStatus): Promise<Response> {
    return service({
      url: '/subject/tutor/editTutorStatus',
      method: 'post',
      params: { id, status }
    })
  },
  /**
   * @description 获取导师列表(选择框)
   * @author Jinx
   * @date 2021-08-23 09:27:01
   * @returns {*}  {Promise<Response>}
   */
  subjectTutorGetTutorListSelect(): Promise<Response> {
    return service({
      url: '/mall-admin/subject/tutor/getTutorListSelect',
      method: 'get'
    })
  },

  /**
   * @description 根据课程id获取报名信息
   * @author Jinx
   * @date 2021-08-27 09:52:55
   * @param {CaphterParams} params
   * @returns {*}  {Promise<Response>}
   */
  subjectSubjectGetSignUpMemberBySubjectId(params: CaphterParams): Promise<Response> {
    return service({
      url: '/subject/subject/getSignUpMemberBySubjectId',
      method: 'get',
      params
    })
  },

  /**
   * @description 根据课程id获取章节列表
   * @author Jinx
   * @date 2021-11-02 16:43:18
   * @param {number} id
   * @returns {*}  {Promise<Response>}
   */
  subjectSubjectGetChapterBySubjectId(id: number): Promise<Response> {
    return service({
      url: `/subject/subject/getChapterBySubjectId/${id}`,
      method: 'get'
    })
  },

  /**
   * @description 批量添加签到小节
   * @author Jinx
   * @date 2021-11-02 16:57:40
   * @param {{chapterIds: string}} params
   * @param {Array<number>} data
   * @returns {*}  {Promise<Response>}
   */
  subjectSubjectSignUpAddSubjectSignUpChapter(params: {chapterIds: string, returnPrice: string}, data: Array<number>): Promise<Response> {
    return service({
      url: `/subject/subjectSignUp/addSubjectSignUpChapter`,
      method: 'post',
      params,
      data
    })
  },
  /**
   * @description 批量添加返款金额
   * @author Jinx
   * @date 2021-11-02 17:38:27
   * @param {{returnPrice: string}} params
   * @param {Array<number>} data
   * @returns {*}  {Promise<Response>}
   */
  subjectSubjectSignUpAddSubjectReturnPrice(params: {returnPrice: string}, data: Array<number>): Promise<Response> {
    return service({
      url: `/subject/subjectSignUp/addSubjectReturnPrice`,
      method: 'post',
      params,
      data
    })
  },

  /**
   * @description 导出课程报名表格
   * @author Jinx
   * @date 2021-08-27 10:27:39
   * @param {number} id
   * @returns {*}  {Promise<any>}
   */
  subjectSubjectSignUpExportSubjectSignUp(id: number, data: { fileType: string, [prop:string]: any }): Promise<any> {
    return exportFile({
      url: `/subject/subjectSignUp/exportSubjectSignUp/${id}`,
      method: 'post',
      isDown: true,
      ...data
    })
  }

}

export default api
