import api from "@/api";
import { Admin, UmsAdminParam } from "@/models/admin";
import { getFile } from "@/utils";
import { isEmail } from "@/utils/validate";
import { ElNotification } from "element-plus";
import { ElFormItemContext } from "element-plus/lib/el-form";
import { Options, Vue } from "vue-class-component";

/**
 * @description 个人中心
 * @author Jinx
 * @date 2021-08-13 15:14:50
 * @export
 * @class CompanyMine
 * @extends {Vue}
 */
@Options({
  data() {
    return {
      form: {}
    }
  }
})
export default class CompanyMine extends Vue {

  private loading = false

  /**
   * @description 判断电邮是否正确
   * @author Jinx
   * @date 2021-08-13 14:45:41
   * @private
   * @param {*} rule
   * @param {*} value
   * @param {function} callback
   * @memberof CompanyMine
   */
  private emailValidate = (rule, value, callback): void => {
    if(!value) {
      callback(new Error('电邮不能为空'))
    } else if (!isEmail(value)) {
      callback(new Error('请检查电邮格式是否正确'))
    } else {
      callback()
    }
  }

  private submitForm: Admin = {
    username: '',
    phone: '',
    email: '',
    icon: ''
  }

  private fromRules = {
    email: [
      { required: true, validator: this.emailValidate }
    ]
  }

  /**
   * @description 上传头像
   * @author Jinx
   * @date 2021-08-13 14:50:53
   * @memberof CompanyMine
   */
  upLoadImg(): void {
    getFile(files => {
      if(!(files.length)) {
        return
      }
      this.loading = true
      const formData = new FormData()
      formData.append('file', files[0])

      api.commonOssUpload(formData).then(res => {
        this.submitForm.icon = res.data
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    }, 'AVATAR', 'image/*')
  }

  getInfo(): void {
    api.adminInfo().then(res => {
      this.submitForm = res.data.info
    })
  }


  submit(): void {
    const __form = this.$refs.form as ElFormItemContext
    __form.validate(v => {
      if(v) {
        const obj = Object.assign({}, this.submitForm) as UmsAdminParam
        api.adminUpdate(obj.id as number, obj).then(res => {
          ElNotification({
            type: 'success',
            title: '成功',
            message: '保存成功'
          })
          this.getInfo()
        })
      } else {
        return false
      }
    })
  }

  render(): JSX.Element {
    return <el-form
        ref="form"
        model={ this.submitForm }
        rules={ this.fromRules }
        label-width="80px"
        class="el-form-tao company-content__mine"
      >
        <el-form-item label="头像" prop="icon">
            {
              this.submitForm.icon
                ? <img src={ this.submitForm.icon } class="company-content__img" />
                : <i class={['company-content__icon', `el-icon-${this.loading ? 'loading' : 'picture'}`]}/>
            }
            <span class="company-content__upload pointer" onClick={ this.upLoadImg }>上传图片</span>
        </el-form-item>
        <el-form-item label="用户名" prop="username">
          <el-input v-model={ this.submitForm.username } clearable />
        </el-form-item>
        <el-form-item label="手机号" prop="icon">
          <el-input v-model={ this.submitForm.phone } clearable />
        </el-form-item>
        <el-form-item label="电邮" prop="email">
          <el-input v-model={ this.submitForm.email } clearable />
        </el-form-item>
        <el-form-item label="" >
          <div class="company-btn" onClick={this.submit}>保存</div>
        </el-form-item>
      </el-form>
  }

  mounted(): void {
    this.getInfo()
  }
}
