import { defineComponent, toRefs } from "vue";
import './index.scss'
import { debounceClick } from "@/utils";
import { PopoverListRow } from "@/models/type";

const Button = defineComponent({
  props: {
    list: {
      type: Array,
      required: true
    },
    onClick: Function,
    onPopoverClick: Function
  },
  emits: ['click', 'popoverClick'],
  setup(props, { emit }) {

    const { list } = toRefs(props)

    const button = (item: ButtonContext): JSX.Element => <span
      class={[
        'button pointer',
        item.type ? `button_${ item.type }` : 'button_gray'
      ]}
      onClick={() => debounceClick(() => emit('click', item.click))}
    >
      <i class={['el-icon', item.loading ? 'el-icon-loading' : item.icon ]} />
      <span class="button-title">{ item.title }</span>
    </span>

    const content = (popoverList: Array<any>): JSX.Element =>
      <div class="button_popover">{ popoverList?.map((item: PopoverListRow) =>
        <span class="button_popover-label" onClick={() => debounceClick(() => emit('popoverClick', item.click))}>{ item.title }</span>) }</div>

    return () => list.value.map((item: any) => item.popover
      ? <el-popover
          placement="bottom"
          width="200"
          trigger="click"
          v-slots={{
            reference: () => button(item),
            default: () => content(item.popoverList)
          }}
        />
      : button(item)
    )
  }
})

interface ButtonContext {
  type?: string
  click?: string
  loading?: boolean
  icon?: string
  title?: string
}

export default Button
