import { Options, Vue } from "vue-class-component"
import { RouteRecordRaw } from "vue-router"
import SidebarItem from "./SidebarItem"
import base from "@/style/base.scss"
import Logo from "./Logo"

@Options({
  components: { SidebarItem },
  computed: {
    activeMenu(){
      const { meta, path } = this.$route
      if(meta.activeMenu) {
        return meta.activeMenu
      }
      return path
    },
    showLogo() {
      return this.$store.state.settings.sidebarLogo
    },
    variables() {
      return base
    },
    isCollapse() {
      return false
    },
    permission_routes(){
      return this.$store.getters.GetPermissionRoutes || []
    }
  }
})
export default class Sidebar extends Vue {

  variables?: any
  activeMenu?: string
  permission_routes?: Array<RouteRecordRaw>
  isCollapse?: boolean
  showLogo?: boolean

  menuDom(): JSX.Element {
    return <el-menu
      defaultActive={this.activeMenu}
      collapse={this.isCollapse}
      backgroundColor={this.variables.menuBg}
      textColor={this.variables.menuText}
      uniqueOpened={true}
      activeTextColor={this.variables.menuActiveText}
      collapseTransition={false}
      mode="vertical"
    >
      {this.permission_routes?.map(route => <SidebarItem key={route.path} item={route} basePath={route.path} /> ) }
    </el-menu>
  }

  render(): JSX.Element {
    return (
      <div class={{ 'has-log': this.showLogo }}>
        <Logo/>
        <el-scrollbar wrap-class="scrollbar-wrapper">
          {this.menuDom()}
        </el-scrollbar>
      </div>
    )
  }
}
