import { Response } from "@/models/request"
import service from "@/api/request"

// 商品分类管理
const api = {
  /**
   * @description 查询所有一级分类及子分类
   * @author Jinx
   * @date 2021-10-19 17:48:13
   * @returns {*}  {Promise<Response>}
   */
  productCateListGorywithChildren(): Promise<Response> {
    return service({
      url: '/productCategory/list/withChildren',
      method: 'get',
    })
  },
  /**
   * @description 添加产品分类
   * @author Jinx
   * @date 2021-10-19 17:52:20
   * @param {ProductCategoryParam} data
   * @returns {*}  {Promise<Response>}
   */
  productCategoryCreate(data: ProductCategoryParam): Promise<Response> {
    return service({
      url: '/productCategory/create',
      method: 'post',
      data
    })
  },
  /**
   * @description 修改商品分类
   * @author Jinx
   * @date 2021-10-19 17:55:42
   * @param {ProductCategoryParam} data
   * @returns {*}  {Promise<Response>}
   */
  productCategoryUpdate(data: ProductCategoryParam): Promise<Response> {
    return service({
      url: `/productCategory/update/${data.id}`,
      method: 'post',
      data
    })
  },
  /**
   * @description 删除商品分类
   * @author Jinx
   * @date 2021-10-19 17:58:36
   * @param {number} id
   * @returns {*}  {Promise<Response>}
   */
  productCategoryDelete(data: Array<number>): Promise<Response> {
    return service({
      url: `/mall-admin/productCategory/delete`,
      method: 'post',
      data
    })
  }
}

export default api

export interface ProductCategoryParam {
  id?: number
  name: string
  keywords?: string
  parentId: number
}
