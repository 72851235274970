import { Response } from "@/models/request"
import service from "@/api/request"
import { CouponParam } from "@/models/coupon"
import { PaginationRow } from "@/models/type"

// 优惠券管理
const api = {
  /**
   * @description 添加优惠券
   * @author Jinx
   * @date 2021-10-20 14:26:24
   * @param {CouponParam} data
   * @returns {*}  {Promise<Response>}
   */
  couponCreate(data: CouponParam): Promise<Response> {
    return service({
      url: '/coupon/create',
      method: 'post',
      data
    })
  },
  /**
   * @description 修改优惠券
   * @author Jinx
   * @date 2021-10-20 14:28:36
   * @param {CouponParam} data
   * @returns {*}  {Promise<Response>}
   */
  couponUpdate(data: CouponParam): Promise<Response> {
    return service({
      url: `/coupon/update/${data.id}`,
      method: 'post',
      data
    })
  },
  /**
   * @description 删除优惠券
   * @author Jinx
   * @date 2021-10-20 14:28:23
   * @param {Array<number>} data
   * @returns {*}  {Promise<Response>}
   */
  couponDelete(data: Array<number>): Promise<Response> {
    return service({
      url: `/coupon/delete`,
      method: 'post',
      data
    })
  },
  /**
   * @description 获取单个优惠券的详细信息
   * @author Jinx
   * @date 2021-10-20 14:28:20
   * @param {number} id
   * @returns {*}  {Promise<Response>}
   */
  coupon(id: number): Promise<Response> {
    return service({
      url: `coupon/${id}`,
      method: 'get'
    })
  },
  /**
   * @description 根据优惠券名称和类型分页获取优惠券列表
   * @author Jinx
   * @date 2021-10-20 14:26:25
   * @param {PaginationRow} params
   * @returns {*}  {Promise<Response>}
   */
  couponList(params: PaginationRow): Promise<Response> {
    return service({
      url: '/coupon/list',
      method: 'get',
      params
    })
  }
}

export default api

