import { reqAssetsSvgs, reqSvgs } from "@/utils";
import { isExternal } from "@/utils/validate";
import { computed, defineComponent, toRefs } from "vue";
import './index.scss'

reqSvgs() // 引入所有svg文件
reqAssetsSvgs() // 引入所有icon文件

/**
 *  SvgIcon svg展示
 *  @type {*}
 */
const SvgIcon = defineComponent({
  props: {
    iconClass: {
      type: String,
      required: true,
    },
    className: {
      type: String,
      default: ''
    }
  },
  setup(props){
    const { iconClass, className } = toRefs(props)

    const externalBool = computed(() => isExternal(iconClass.value))
    const iconName = computed(() => `#icon-${iconClass.value}`)
    const svgClass = computed(() => className.value ? `svg-icon ${className.value}` : 'svg-icon')
    const styleExternalIcon = computed(() => ({
      mask: `url(${iconClass.value}) no-repeat 50% 50%`,
      '-webkit-mask': `url(${iconClass.value}) no-repeat 50% 50%`
    }))

    const isDom = <div style={ styleExternalIcon.value } class="svg-external-icon svg-icon" />

    const unDom = <svg class={ svgClass.value } aria-hidden="true">
      <use xlinkHref={ iconName.value } />
    </svg>
    return () => ( externalBool.value ? isDom : unDom )
  }
})

export default SvgIcon
