import Parameter from "@/config/parameter"
import { commitT } from "@/models/type"
import { ElNotification } from "element-plus"
import debounce from 'lodash/debounce'

/**
 * @description 拉起下载动作
 * @author Jinx
 * @date 2021-04-04 12:08:47
 * @export
 * @param {string} __filename 下载文件名
 * @param {Blob} [__blob] blob集合
 */
export function downDom(__filename: string | undefined, __blob?: Blob): void {
  const elink = document.createElement('a')
  if('download' in elink) {
    elink.download = __filename as string
    elink.style.display = 'none'
    elink.href = URL.createObjectURL(__blob)
    document.body.appendChild(elink)
    elink.click()
    URL.revokeObjectURL(elink.href)
    document.body.removeChild(elink)
  } else {
    const winNavigator = window.navigator as any
    winNavigator.msSaveBlob(__blob, __filename)
  }
}


/**
 * @description 引入所有svg文件
 * @author Jinx
 * @date 2021-44-09 09:08:25
 * @export
 */
export function reqSvgs(): void {
  const requireAll = (requireContext: any) => requireContext.keys().map(requireContext)
  const svgFiles = require.context('@/icons/svg', false, /\.svg$/)
  requireAll(svgFiles)
}

/**
 * @description 引入所有icon文件
 * @author Jinx
 * @date 2021-08-23 10:07:43
 * @export
 */
export function reqAssetsSvgs(): void {
  const requireAssetsAll = (requireContext: any) => requireContext.keys().map(requireContext)
  const assetsSvgFiles = require.context('@/assets/svgs', false, /\.svg$/)
  requireAssetsAll(assetsSvgFiles)
}


/**
 * @description 拉起上传文件
 * @author Jinx
 * @date 2021-10-25 18:33:01
 * @export
 * @param {commitT} cb
 * @param {string} imgSize
 * @param {string} [accept]
 * @param {boolean} [multiple]
 * @param {number} [number]
 */
export function getFile(cb: commitT, imgSize: string, accept?: string, multiple?: boolean): void {
  const oInput = document.createElement('input')
  oInput.type = 'file'
  oInput.multiple = multiple ? true : false
  if(accept) {
    oInput.accept = accept
  }
  oInput.click()
  oInput.onchange = function (event) {
    const __target = event.target as any
    const arr: Array<string> = []
    imgSizeCheck({
      fileList: __target.files,
      imgSize,
      success: (indexArr?: Array<number>) => {
        indexArr?.forEach(ix => {
          arr.push(__target.files[ix])
        })
        cb(arr)
      },
      error: (err: string) => {
        ElNotification({
          title: '上传警告',
          type: 'warning',
          message: err,
          duration: 0,
          showClose: true
        })
      }
    })
  }
}

/**
 * @description 校验图片尺寸
 * @author Jinx
 * @date 2021-09-24 17:40:05
 * @export
 * @param {Array<File>} fileList
 * @param {string} imgSize
 * @param {commitT} success
 * @param {commitT} error
 */
export async function imgSizeCheck({
  fileList,
  imgSize,
  success,
  error
}: {fileList: Array<File>, imgSize: string, success: commitT, error: (err: string, index?: number) => void}): Promise<void> {
  // if(fileList[0].size >= 10485760) {
  //   error('上传文件不能大于10M')
  //   return
  // }
  if(!imgSize || fileList[0].type.includes('video')) {
    const arr: number[] = []
    for(let i = 0; i < fileList.length; i++) {
      arr.push(i)
    }
    success(arr)
    return
  }
  if(imgSize === 'WORK') {
    // 作品只判断宽度
    const imgUrl = window.URL || window.webkitURL
    const oImage = new Image()
    oImage.src = imgUrl.createObjectURL(fileList[0])
    oImage.onload = function() {
      // if(oImage.width === Parameter[imgSize]) {
        success([0])
      // }else {
        // error(`请重新上传宽度为 ${Parameter[imgSize]} 的图片`, )
      // }
    }
    return
  }
  const arrIndex: Array<number> = []
  for(let i = 0; i < fileList.length; i ++) {
    const isTrue = await imgFn(fileList[i], imgSize)
    if(isTrue === 1) {
      arrIndex.push(i)
    } else {
      error(isTrue)
    }
  }
  success(arrIndex)
}

export function imgFn(file: File, imgSize: string): Promise<any> {
  return new Promise((resolve: any) => {
    const imgUrl = window.URL || window.webkitURL
    const oImage = new Image()
    oImage.src = imgUrl.createObjectURL(file)
    oImage.onload = function() {
      // if(oImage.width / oImage.height ===  Parameter[imgSize][0] / Parameter[imgSize][1]) {
        resolve(1)
      // } else {
        // resolve(`请重新上传宽高比例为 ${Parameter[imgSize][0]} / ${Parameter[imgSize][1]} 的图片`)
      // }
    }
  })
}

export const /**
 * @description 防抖函数
 * @author Jinx
 * @date 2021-08-17 15:16:10
 * @param {commitT} cb
 */
debounceClick = debounce(function(cb: commitT): void {
  // 防抖
  cb()
}, 500)


/**
 * @description 标题
 * @author Jinx
 * @date 2021-08-17 15:17:08
 * @export
 * @param {string} val
 * @returns {*}  {string}
 */
export function typeWatch(val?: string): string {
  switch (val) {
    case 'add':
      return '新增'
    case 'edit':
      return '编辑'
    default:
      return ''
  }
}


/**
 * @description 时间过滤
 * @author Jinx
 * @date 2021-08-18 14:31:15
 * @export
 * @param {(string | number)} time
 * @param {string} cFormat
 * @returns {*}  {(string | null)}
 */
export function parseTime(time: string | number, cFormat?: string): string | null {
  if (arguments.length === 0 || !time) {
    return null
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if ((typeof time === 'string')) {
      if ((/^[0-9]+$/.test(time))) {
        // support "1548221490638"
        time = parseInt(time)
      } else {
        // support safari
        // https://stackoverflow.com/questions/4310953/invalid-date-in-safari
        time = time.replace(new RegExp(/-/gm), '/')
      }
    }

    if ((typeof time === 'number') && (time.toString().length === 10)) {
      time = time * 1000
    }
    date = new Date(time)
  }
  const formatObj: any = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  }
  const time_str = format.replace(/{([ymdhisa])+}/g, (result, key) => {
    const value = formatObj[key]
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') { return ['日', '一', '二', '三', '四', '五', '六'][value ] }
    return value.toString().padStart(2, '0')
  })
  return time_str
}

/**
 * @description 获取时间
 * @author Jinx
 * @date 2021-10-21 18:32:36
 * @export
 * @param {number} day
 * @returns {*}  {(Array<string | Date | null>)}
 */
export function getTimeCell(day: number): Array<string | Date | null> {
  // 获取时间
  const time: Array<string | null> = []
  if (day === 1) {
    const today = new Date()
    today.setHours(0, 0, 0, 0)
    time.push(parseTime(today.getTime()), parseTime(new Date().getTime()))
  } else if (day === -1) {
    const end = new Date() // 结数时间
    const start = new Date() // 开始时间
    start.setTime(start.getTime() - 3600 * 1000 * 24 * 1)
    time.push(parseTime(start.getTime()), parseTime(end.getTime()))
  } else {
    const end = new Date() // 结数时间
    const start = new Date() // 开始时间
    start.setTime(start.getTime() - 3600 * 1000 * 24 * day)
    time.push(parseTime(start.getTime()), parseTime(end.getTime()))
  }
  return time
}
