import { Response } from "@/models/request"
import service from "@/api/request"
import { PaginationRow } from "@/models/type"
import { MoneyInfoParam } from "@/models/order"

// 优惠券管理
const api = {
  orderList(params: OrderListParams): Promise<Response> {
    return service({
      url: '/order/list',
      method: 'get',
      params
    })
  },
  orderListByStatusStr(params: OrderListParams): Promise<Response> {
    return service({
      url: '/order/listByStatusStr',
      method: 'get',
      params
    })
  },
  order(id: number): Promise<Response> {
    return service({
      url: `/order/${id}`,
      method: 'get'
    })
  },
  /**
   * @description 修改订单费用信息
   * @author Jinx
   * @date 2021-10-20 19:34:41
   * @param {MoneyInfoParam} data
   * @returns {*}  {Promise<Response>}
   */
  orderUpdateMoneyInfo(data: MoneyInfoParam): Promise<Response> {
    return service({
      url: `/order/update/moneyInfo`,
      method: 'post',
      data
    })
  },
  /**
   * @description 批量发货
   * @author Jinx
   * @date 2021-10-20 18:34:50
   * @param {Array<{deliveryCompany: string, deliverySn: string, orderId: number}>} data
   * @returns {*}  {Promise<Response>}
   */
  orderUpdateDelivery(data: Array<{deliveryCompany: string, deliverySn: string, orderId: number}>): Promise<Response> {
    return service({
      url: `/order/update/delivery`,
      method: 'post',
      data
    })
  }
}

export default api


export interface OrderListParams extends PaginationRow {
  orderSn?: string
  productSn?: string
  receiverKeyword?: string
  status?: string
  memberId?: number
}
