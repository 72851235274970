
import { Cb } from '@/models/type'
import defaultSettings from '@/settings'

const { fixedHeader, sidebarLogo, paginationObj } = defaultSettings

const settingsStore = {
  state: {
    fixedHeader,
    sidebarLogo,
    paginationObj
  },
  getters: {
    GetFixedHeader: (state): Cb => state.fixedHeader,
    GetPaginationObj: (state): Cb => state.paginationObj
  }
}

export default settingsStore
