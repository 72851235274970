import { Response } from "@/models/request"
import service from "@/api/request"
import { ResourceParams, UmsResource, UmsResourceCategory } from "@/models/resource"

/* 资源管理 */
const api = {
  /**
   * @description 分页模糊查询后台资源
   * @author Jinx
   * @date 2021-08-18 14:42:33
   * @param {ResourceParams} params
   * @returns {*}  {Promise<Response>}
   */
  resourceList(params: ResourceParams): Promise<Response> {
    return service({
      url: '/resource/list',
      method: 'get',
      params
    })
  },

  /**
   * @description 添加后台资源
   * @author Jinx
   * @date 2021-08-18 15:07:15
   * @param {UmsResource} data
   * @returns {*}  {Promise<Response>}
   */
  resourceCreate(data: UmsResource): Promise<Response> {
    return service({
      url: '/resource/create',
      method: 'post',
      data
    })
  },

  /**
   * @description 修改后台资源
   * @author Jinx
   * @date 2021-08-18 15:07:17
   * @param {number} id
   * @param {UmsResource} data
   * @returns {*}  {Promise<Response>}
   */
  resourceUpdate(id: number, data: UmsResource): Promise<Response> {
    return service({
      url: `/resource/update/${id}`,
      method: 'post',
      data
    })
  },

  /**
   * @description 根据ID删除后台资源
   * @author Jinx
   * @date 2021-08-18 15:14:30
   * @param {Array<number>} data
   * @returns {*}  {Promise<Response>}
   */
  resourceDelete(data: Array<number>): Promise<Response> {
    return service({
      url: `/resource/delete`,
      method: 'post',
      data
    })
  },

  /**
   * @description 查询所有后台资源
   * @author Jinx
   * @date 2021-08-18 16:39:51
   * @returns {*}  {Promise<Response>}
   */
  resourceListAll(): Promise<Response> {
    return service({
      url: `/resource/listAll`,
      method: 'get'
    })
  },

  /**
   * @description 查询所有后台资源分类
   * @author Jinx
   * @date 2021-08-18 14:43:52
   * @returns {*}  {Promise<Response>}
   */
  resourceCategoryListAll(): Promise<Response> {
    return service({
      url: '/resourceCategory/listAll',
      method: 'get'
    })
  },

  /**
   * @description 添加后台资源分类
   * @author Jinx
   * @date 2021-08-18 15:57:42
   * @returns {*}  {Promise<Response>}
   */
  resourceCategoryCreate(data: UmsResourceCategory): Promise<Response> {
    return service({
      url: '/resourceCategory/create',
      method: 'post',
      data
    })
  },

  /**
   * @description 修改后台资源分类
   * @author Jinx
   * @date 2021-08-18 15:58:54
   * @param {number} id
   * @returns {*}  {Promise<Response>}
   */
  resourceCategoryUpdate(id: number, data: UmsResourceCategory): Promise<Response> {
    return service({
      url: `/resourceCategory/update/${id}`,
      method: 'post',
      data
    })
  },

  resourceCategoryDelete(id: number): Promise<Response> {
    return service({
      url: `/resourceCategory/delete/${id}`,
      method: 'post'
    })
  },

}

export default api
