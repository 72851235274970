import api from "@/api";
import Button from "@/components/Button";
import Search from "@/components/Search";
import { SysMonth, SysSelectTypeOption } from "@/models/select";
import { ButtonListRow, commitT, SearchListRow, TableRow } from "@/models/type";
import { debounceClick, parseTime, typeWatch } from "@/utils";
import { ElMessageBox, ElNotification } from "element-plus";
import { Options, Vue } from "vue-class-component";

@Options({
  watch: {
    type(val?: string): void {
      this.title = typeWatch(val)
    },
    deteleSum(sum: number): void {
      if(sum) {
        this.getList()
      }
    }
  }
})
export default class BaseMonth extends Vue {

  deteleSum = 0
  title = ''
  type = ''

  monthForm: SysMonth = {
    year: '',
    startMonth: '',
    endMonth: ''
  }

  private searchObj: { year: string } = {
    year: parseTime(new Date().getTime(), '{y}') as string
  }

  private searchList: Array<SearchListRow> = [
    {label: '年份', type: 'year', submitKey: 'year', value: this.searchObj.year}
  ]

  private validateStartMonth = (rule: any, value: any, callback: commitT): void => {
    const year = value && value.split('-')[0]
    if(!value) {
      return callback(new Error('请选择起始月份'))
    } else if (year !== this.monthForm.year) {
      return callback(new Error('年份必须与所选年份相同'));
    } else {
      return callback()
    }
  }

  private validateEndMonth = (rule: any, value: any, callback: commitT): void => {
    const year = value && +value.split('-')[0]
    if(!value) {
      return callback(new Error('请选择起始月份'))
    } else if (year - 1 == this.monthForm.year || year == this.monthForm.year) {
      return callback()
    } else {
      return callback(new Error('年份必须与所选年份相同或大于一年'));
    }
  }

  formRules: any = {
    year: [
      { required: true, message: '请选择年', trigger: 'change' }
    ],
    startMonth: [
      { required: true, trigger: 'change', validator: this.validateStartMonth }
    ],
    endMonth: [
      { required: true, trigger: 'change', validator: this.validateEndMonth }
    ]
  }
  ids: Array<number> = []
  detailBool = false
  private buttonList: Array<ButtonListRow> = [
    { title: '添加', icon: 'el-icon-plus', click: 'monthAdd' },
    { title: '删除', icon: 'el-icon-minus', type: 'danger', click: 'monthDel' }
  ]

  public total = 0

  private tableList: Array<TableRow> = [
    { label: '序号', slot: {
      default: (scope: any): JSX.Element => <span>{ scope.$index + 1 }</span>
    } },
    { label: '月份', prop: 'fullName' },
    { label: '操作', slot: {
      default: (scope: any): JSX.Element => <div>
        <span class="span-button-primary" onClick={ () => this.monthEdit(scope.row) }>编辑</span>
        <span class="span-button-danger" onClick={ () => this.monthDel(scope.row.id) }>删除</span>
      </div>
    } }
  ]

  tableData: Array<any> = []

  buttonClick(click: string): void {
    this[click]()
  }

  monthEdit(row: SysMonth): void {
    const form = Object.assign({}, row)
    const year = form.year as number
    const startMonth = form.startMonth
    const endMonth = form.endMonth
    form.year = year.toString()
    form.startMonth = `${year}-${startMonth}`
    form.endMonth = startMonth > endMonth ? `${year + 1}-${endMonth}` : `${year}-${endMonth}`
    this.monthForm = form
    this.type = 'edit'
    this.detailBool = true
  }

  monthAdd(): void {
    this.type = 'add'
    this.detailBool = true
  }

  monthDel(id: number): void {
    const ids = id ? [id] : this.ids
    if(!(ids.length > 0)) {
      ElNotification({
        type: 'warning',
        message: '请选择要删除的月份列表'
      })
      return
    }
    ElMessageBox.confirm('确定删除该月份？', '删除月份', {
      type: 'warning'
    }).then(() => {
      api.selectDeleteMonth(ids).then(() => {
        ElNotification({
          title: '成功',
          type: 'success',
          message: '删除月份成功'
        })
        id ? this.deteleSum++ : this.getList()
      })
    }).catch(err => {
      return err
    })
  }

  getList(): void {
    api.selectGetMonth(this.searchObj).then(res => {
      this.tableData = res.data
    })
  }

  tableDom(): JSX.Element {
    return (
      <el-table
        data={ this.tableData }
        onSelectionChange={ this.selectionChange }
      >
        <el-table-column type="selection" width="55" />
        {this.tableList.map(item => (
          <el-table-column
          prop={ item.prop }
          label={ item.label }
          align={ item.align || 'center' }
          v-slots={ item.slot }
        />
        ))}
      </el-table>
    )
  }

  selectionChange(row: Array<SysSelectTypeOption>): void {
    this.ids = row.map(item => item.id) as Array<number>
  }

  detailClose(): void {
    this.detailBool = false
    this.type = ''
    this.monthForm = {
      year: '',
      startMonth: '',
      endMonth: ''
    }
  }

  detailDom(): JSX.Element {

    const slots: any = {
      footer: (): JSX.Element => (
        <span class="dialog-footer">
          <button class="dialog-footer-btn dialog-footer-btn-l" onClick={ this.detailClose } >取 消</button>
          <button class="dialog-footer-btn dialog-footer-btn-r" onClick={ () => debounceClick(this.submit) }>确 定</button>
        </span>
      )
    }

    return (
      <el-dialog
        title={ `${ this.title }月份` }
        v-model={ this.detailBool }
        before-close={ this.detailClose }
        width="30%"
        v-slots={ slots }
      >
        <el-form
          ref="form"
          model={ this.monthForm }
          rules={ this.formRules }
          label-width="120px"
          class="el-form-tao"
        >
          <el-form-item label="起始月份" prop="year">
            <el-date-picker
              style="width: 100%"
              v-model={ this.monthForm.year }
              type="year"
              placeholder="选择年"
              value-format="YYYY"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="起始月份" prop="startMonth">
            <el-date-picker
              style="width: 100%"
              v-model={ this.monthForm.startMonth }
              type="month"
              placeholder="选择起始月"
              value-format="YYYY-M"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="结束月份" prop="endMonth">
            <el-date-picker
              style="width: 100%"
              v-model={ this.monthForm.endMonth }
              type="month"
              placeholder="选择结束月"
              value-format="YYYY-M"
            >
            </el-date-picker>
          </el-form-item>
        </el-form>
      </el-dialog>
    )
  }

  submit(): void {
    const __form = this.$refs.form as any
    __form.validate(v => {
      if(v) {
        const form = Object.assign({}, this.monthForm)
        const start = form.startMonth as string
        const end = form.endMonth as string
        form.year = +form.year
        form.startMonth = +start.split('-')[1]
        form.endMonth = +end.split('-')[1]
        this.type === 'add'
          ? this.submitAdd(form)
          : this.submitEdit(form)
      } else {
        return false
      }
    })
  }

  submitAdd(form: SysMonth): void {
    delete form.id
    api.selectAddMonth(form).then(() => {
      ElNotification({
        title: '成功',
        type: 'success',
        message: '新增月份成功'
      })
      this.detailClose()
      this.getList()
    })
  }

  submitEdit(form: SysMonth): void {
    api.selectEditMonth(form).then(() => {
      ElNotification({
        title: '成功',
        type: 'success',
        message: '编辑月份成功'
      })
      this.detailClose()
      this.getList()
    })
  }

  onSearch(row: never): void {
    this.searchObj = row
    this.getList()
  }

  render(): JSX.Element {
    return (
      <div class="base-month">
        <Search list={ this.searchList } onSearch={ this.onSearch } />
        <Button list={ this.buttonList } onClick={ this.buttonClick } />
        {this.tableDom()}
        {this.detailBool && this.detailDom()}
      </div>
    )
  }

  mounted(): void {
    this.getList()
  }
}
