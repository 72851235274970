/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { RouteRecordRaw } from "vue-router";
import Sign from '@/views/sign/index.vue'

const Classing = () => import('@/views/sign/classing')
const Doing = () => import('@/views/sign/doing')

/* 报名管理 */
const signRoute: RouteRecordRaw = {
  path: '/sign',
  component: Sign,
  name: 'Sign',
  redirect: '/sign/classing',
  meta: { title: '报名管理', icon: 'sign', roles: ['报名管理'] },
  children: [
    {
      path: 'classing',
      component: Classing,
      name: 'Classing',
      meta: { title: '课程报名', roles: ['报名管理'] },
    },
    {
      path: 'doing',
      component: Doing,
      name: 'Doing',
      meta: { title: '活动报名', roles: ['报名管理'] },
    }
  ]
}

export default signRoute
